import { useStyleConfig, chakra } from "@chakra-ui/react";

const Spinner = ({ size }) => {

    const style = useStyleConfig("SpinnerWheel", { size });

    // console.log(style);

    return (
        <chakra.svg __css={style} viewBox="0 0 16 16" fillRule="evenodd">
            <path d="M5.931,0.274C10.195,-0.869 14.586,1.666 15.729,5.931C16.872,10.195 14.337,14.586 10.072,15.729C5.807,16.872 1.417,14.337 0.274,10.072C-0.869,5.807 1.666,1.417 5.931,0.274ZM7.761,3.751L7.323,2.117C8.851,1.938 10.349,2.369 11.531,3.245L10.335,4.44L11.563,5.668L12.759,4.472C13.191,5.054 13.524,5.726 13.723,6.469C13.922,7.211 13.97,7.959 13.886,8.679L12.252,8.241L11.803,9.919L13.436,10.356C12.85,11.706 11.769,12.827 10.357,13.437L9.919,11.803L8.242,12.252L8.68,13.886C7.152,14.065 5.654,13.634 4.472,12.758L5.668,11.563L4.44,10.335L3.244,11.531C2.812,10.949 2.479,10.277 2.28,9.534C2.081,8.792 2.033,8.044 2.116,7.324L3.751,7.762L4.2,6.084L2.567,5.647C3.153,4.297 4.234,3.176 5.646,2.566L6.084,4.2L7.761,3.751Z">
            <animateTransform attributeName="transform"
            attributeType="XML"
            type="rotate"
            from="360 8 8"
            to="0 8 8"
            dur="2s"
            repeatCount="indefinite"/>
            </path>
        </chakra.svg>
    )
};

export default Spinner;
