import { useBreakpointValue } from "@chakra-ui/react";
import { isEqual, merge } from "lodash";
import { useEffect } from "react";
import { useLayoutEffect } from "react";
import { useApp, useS, useUpdateApp } from "./store";

const sizes = ["base", "sm", "md", "lg", "xl", "2xl"];

const config = {
    base: {
        item: {
            order: 1,
        },
        toc: {
            order: 2,
            drawer: true,
            placement: "bottom",
        },
        tools: {
            order: 3,
            drawer: true,
            placement: "bottom",
        },
        settings: {
            drawer: true,
            placement: "bottom",
        }
    },
    md: {
        toc: { drawer: false, placement: "right"},
        tools: { placement: "right" },
        settings: { placement: "right" },
    },
    lg: {
        tools: { drawer: false }
    }
};

export const getPanelConfig = () => {
    return sizes.reduce((obj, key, index) => {
        if (index > 0) {
            const res = {};
            merge(res, obj[sizes[index-1]], config[key]||{});
            return {...obj, [key]: res };
        }
        const res = { [key]: config[key]||{} };
        return res;
    }, {});
};

const panelConfig = getPanelConfig();

const getDrawers = panels => Object.entries(panels).reduce((drawers, [panelName, panel]) => (panel.drawer ? {...drawers, [panelName]: panel } : drawers), {});

export const getClosestSize = (obj, size) => {
    if (obj.hasOwnProperty(size)) return size;
    const index = sizes.indexOf(size) - 1;
    if (index < 0) return null;
    for (let i=index; i>=0; i--) {
        if (obj.hasOwnProperty(sizes[i])) return sizes[i];
    }
    return null;
};
export const getClosestSizeObject = (obj, size) => {
    if (obj.hasOwnProperty(size)) return obj[size];
    if (size==="base" && obj.hasOwnProperty("baseStyle")) return obj.baseStyle;
    const index = sizes.indexOf(size) - 1;
    if (index < 0) return null;
    for (let i=index; i>=0; i--) {
        if (obj.hasOwnProperty(sizes[i]) ||
        (sizes[i]==="base" && obj.hasOwnProperty("baseStyle"))) return obj.baseStyle;
    }
    return null;
};

const useSize = () => {
    const sizeName = useBreakpointValue(sizes);
    const update = useUpdateApp();
    const { size } = useApp();
    useLayoutEffect(() => {
        if (!sizeName) return;
        console.log(sizeName);
        update({ size: sizeName });
    }, [sizeName, update]);
    return size;
};

const usePanels = () => {
    const activePanels = useS("user.prefs.activePanels");
    // console.log(activePanels);
    const { size, panels } = useApp();
    const update = useUpdateApp();
    useEffect(() => {
        if (!size) return;
        const updated = Object.entries(panelConfig[size])
        .reduce((config, [name, value]) => activePanels.includes(name) ? {...config, [name]: value } : config, {});
        if (!isEqual(updated, panels)) update({ panels: updated });
    }, [activePanels, size, panels, update]);
    return panels;
};

const useDrawers = () => {
    const { panels, drawers } = useApp();
    const update = useUpdateApp();
    useEffect(() => {
        if (!panels) return;
        // console.log(panels);
        const updated = getDrawers(panels);
        if (!drawers || Object.keys(updated).length !== Object.keys(drawers).length) {
            // console.log("useDrawers: create drawers");
            update({ drawers: updated });
        }
    }, [panels, drawers, update]);
    return drawers;
}

export const Sized = ({ children }) => {

    const size = useSize();
    const panels = usePanels();
    const drawers = useDrawers();

    if (!size) return null;
    if (!panels) return null;
    if (!drawers) return null;

    return (
        children
    )
};
