import { Box, StylesProvider } from "@chakra-ui/react";
import { memo } from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import shallow from "zustand/shallow";
import { round } from "../../../shed";
import { setPause, setPlay, useMarkAsRead, useStore, useUpdatePlayer } from "../../../store";
import Chat from "./Chat";
import Controls from "./Controls";
import Progress from "./Progress";

const speed = 1, step = 0.05;

const Device0 = ({ record, styles, markAsRead, read, onPause }) => {

    // console.log(record);

    const { chat, actors, id } = useMemo(() => record, [record]);
    const duration = useMemo(() => chat[chat.length-1].time, [chat]);

    // console.log(actors.map(a=>a.position));
    // console.log(id);

    const
        frames = useMemo(() => {
            const times = chat.map(line => line.time);
            // console.log(times);
            return times;
        }, [chat]),
        // [index, setIndex] = useState(0),
        [feed, setFeed] = useState(),
        [lineNode, setLineNode] = useState();

    const
        [pos, setPos] = useState(0),
        [time, setTime] = useState(0),
        [startTime, setStartTime] = useState(0),
        [running, setRunning] = useState(false),
        timer = useRef();

    // useEffect(() => { setFeed([id]) }, [id]);

    useEffect(() => {
        // console.log(`time: ${time}`);
        const timeSlice = frames.filter(t => t <= time);
        if (timeSlice.length + 1 === feed?.length) return;
        const chatSlice = chat.filter(line => timeSlice.includes(line.time));
        chatSlice.unshift(id);
        setFeed(chatSlice);
    }, [time, frames, chat, id, feed]);

    useEffect(() => {
        if (lineNode) lineNode.scrollIntoView({ behavior: "smooth" })
    }, [lineNode]);

    const tick = useCallback(() => {
        setTime(current => round(current + step, 3));
    }, []);

    const start = useCallback(() => {
        if (running) return;
        setRunning(true);
        timer.current = setInterval(tick, (step * 1000) / speed);
    }, [running, tick]);

    const pause = useCallback(() => {
        if (!running) return;
        clearInterval(timer.current);
        setRunning(false);
    }, [running]);

    const reset = useCallback(() => {
        if (running) pause();
        setFeed([id]);
        setTime(0);
        /* if (time > 0) {
            setFeed([]);
            // setIndex(0);    
            setTime(0);
        } */
    }, [running, pause, id]);

    useEffect(() => {
        setPos(time / duration);
    }, [time, duration]);

    /* useEffect(() => {
        return () => {
            clearInterval(timer.current);
        }
    }, []); */

    useEffect(() => {
        return () => {
            console.log("%cDevice unmount", "color:orange");
            clearInterval(timer.current);
            setFeed();
            setPos(0);
            setTime(0);
            setStartTime(0);
        }
    }, [record.id]);

    useEffect(() => {
        if (running) return;
        if (startTime===time) return;
        setStartTime(() => {
            // console.log("Device: set start time:", time);
            return time;
        });
    }, [running, time, startTime]);

    useEffect(() => {
        if (time >= duration) {
            pause();
            if (!read) markAsRead(record.id);
        }
    }, [time, duration, pause, read, markAsRead, record.id]);

    useEffect(() => {
        if (onPause) {
            pause();
        }
    }, [onPause, pause, start]);

    /* useEffect(() => {
        console.log(`Device: ${record.title}: read:`, read);
    }, [record.title, read]); */

    // console.log(pos);

    if (!feed || feed[0] !== id) return null;

    return (
        <Box __css={styles.device}
            data-name="player window"
        >
            <StylesProvider value={styles}>
                <Chat {...{ feed, actors, chat, setNode: setLineNode, running, startTime }} />
                <Controls {...{ start, pause, reset, running, middle: time > 0, pos, time, duration }} />
                <Progress {...{ pause, step, duration, time, setTime, read, record }} />
            </StylesProvider>
        </Box>
    )
};

const Device = memo(({ styles, onPause }) => {

    const t = useRef();

    const keys = ["chat", "step", "speed", "pause", "running", "tick", "setTime"];

    const {
        chat, step, speed, pause, running, tick, setTime,
    } = useStore(state => {
        return Object.entries(state.player).reduce((obj, [key, val]) => (
            keys.includes(key) ? {...obj, [key]: val} : obj
        ), {});
    }, shallow);


    useEffect(() => {
        // console.log("Device: subscribe time");
        const unsubscribeTime = useStore.subscribe(
            time => {
                // console.log("Device: time changed:", time);
                // console.log(useStore.getState());
                const {
                    running, setPos, frames,
                    pause, duration, startTime,
                    setStartTime, feed, setFeed,
                    chat,
                } = useStore.getState().player;

                if (running) {
                    // console.log(`Device: setPos to time:`, time);
                    setPos(time === null ? 0 : time);
                }
                
                const timeSlice = frames.filter(t => t <= time);
                if (timeSlice.length === feed.length) return;
                const chatSlice = chat.filter(line => timeSlice.includes(line.time));
                setFeed(chatSlice);

                if (time >= duration) {
                    pause();
                }

                if (!running && startTime !== time) setStartTime(time);

            },
            state => state.player.time
        );
        return () => {
            // console.log("Device: unmount/unsubscribe time");
            unsubscribeTime();
        };
    }, [chat]);

    useEffect(() => {
        setTime(0);
    }, [setTime, chat]);

    /* useEffect(() => {
        const timeSlice = frames.filter(t => t <= time);
        if (timeSlice.length === feed.length) return;
        const chatSlice = chat.filter(line => timeSlice.includes(line.time));
        setFeed(chatSlice);
    }, [time, frames, chat, feed, setFeed]); */

/*     useEffect(() => {
        if (running) return;
        if (startTime === time) return;
        console.log("Device: set start time");
        setStartTime(time);
    }, [running, time, setStartTime, startTime]); */

    useEffect(() => {
        if (!running) {
            // console.log("Device: stop tick");
            clearInterval(t.current);
        } else {
            // console.log("Device: start tick");
            t.current = setInterval(tick, (step * 1000) / speed);
        }
    }, [running, speed, step, tick]);

/*     useEffect(() => {
        if (time >= duration) {
            pause();
        }
    }, [time, duration, pause]); */

    useEffect(() => {
        if (onPause) pause();
    }, [onPause, pause]);

    // useEffect(() => { console.log("Device render") });
    
    return (
        <Box __css={styles.device} name="device">
            <StylesProvider value={styles}>
                <Chat />
                <Controls />
                <Progress />
            </StylesProvider>
        </Box>
    )
});

export default Device;
