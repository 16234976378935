import { Box } from "@chakra-ui/react";
import { motion } from "framer-motion";

const MBox = motion(Box);

const Dot = ({ delay }) => (
    <MBox w="6px" h="6px" mx="2px" display="inline-block"
        animate={{
            y: [4, -4, 4, 4],
        }}
        transition={{
            delay,
            duration: 1,
            repeat: Infinity,
            // times: [],
        }}
    >
        <svg viewBox="0 0 12 12">
            <circle cx="6" cy="6" r="5.8" opacity=".2" />
        </svg>
    </MBox>
);

export default Dot;
