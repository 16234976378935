export const logo = (
    <g>
        <path d="M20.045,12.987C19.809,14.066 19.408,15.065 18.843,15.984C18.279,16.902 17.583,17.695 16.757,18.361C15.931,19.027 15.008,19.549 13.988,19.928C12.968,20.308 11.893,20.497 10.764,20.497C9.449,20.497 8.214,20.249 7.059,19.751C5.905,19.254 4.897,18.576 4.037,17.716C3.178,16.856 2.499,15.849 2.002,14.694C1.505,13.539 1.256,12.313 1.256,11.015C1.256,9.7 1.505,8.465 2.002,7.31C2.499,6.155 3.178,5.148 4.037,4.288C4.897,3.428 5.905,2.75 7.059,2.252C8.214,1.755 9.449,1.506 10.764,1.506C11.893,1.506 12.968,1.696 13.988,2.075C15.008,2.455 15.931,2.982 16.757,3.656C17.583,4.33 18.279,5.123 18.843,6.033C19.408,6.943 19.809,7.938 20.045,9.017L13.723,9.017C13.385,8.528 12.964,8.144 12.458,7.866C11.953,7.588 11.388,7.449 10.764,7.449C10.258,7.449 9.79,7.542 9.361,7.727C8.931,7.913 8.556,8.165 8.235,8.486C7.915,8.806 7.662,9.181 7.477,9.611C7.291,10.041 7.198,10.509 7.198,11.015C7.198,11.503 7.291,11.967 7.477,12.405C7.662,12.844 7.915,13.223 8.235,13.543C8.556,13.864 8.931,14.116 9.361,14.302C9.79,14.487 10.258,14.58 10.764,14.58C11.388,14.58 11.953,14.437 12.458,14.15C12.964,13.864 13.385,13.476 13.723,12.987L20.045,12.987Z" />
        <path d="M17.971,1.886L25.153,1.886L28.086,13.113L31.019,1.886L37.771,1.886L31.955,20.118L23.812,20.118L17.971,1.886Z" />
        <path d="M38.024,1.886L47.507,1.886C48.384,1.886 49.205,2.05 49.972,2.379C50.739,2.708 51.41,3.159 51.983,3.732C52.556,4.305 53.007,4.975 53.336,5.742C53.664,6.509 53.829,7.331 53.829,8.208C53.829,9.32 53.563,10.34 53.032,11.267C52.501,12.195 51.789,12.945 50.895,13.518L53.905,20.118L46.344,20.118L44.219,14.226L44.219,20.118L38.024,20.118L38.024,1.886ZM46.42,10.256C46.807,10.256 47.153,10.108 47.456,9.813C47.76,9.518 47.911,9.169 47.911,8.764C47.911,8.376 47.76,8.035 47.456,7.74C47.153,7.445 46.807,7.297 46.42,7.297L44.219,7.297L44.219,10.256L46.42,10.256Z" />
        <rect x="54.082" y="9.244" width="9.635" height="3.439" />
        <path d="M70.443,20.118L64.247,20.118L64.247,7.702L61.794,7.702L61.794,1.886L70.443,1.886L70.443,20.118Z" />
        <path d="M70.696,11.015C70.696,9.7 70.944,8.465 71.442,7.31C71.939,6.155 72.617,5.148 73.477,4.288C74.337,3.428 75.344,2.75 76.499,2.252C77.654,1.755 78.889,1.506 80.204,1.506C81.519,1.506 82.749,1.755 83.896,2.252C85.042,2.75 86.045,3.428 86.905,4.288C87.765,5.148 88.443,6.155 88.941,7.31C89.438,8.465 89.687,9.7 89.687,11.015C89.687,12.313 89.438,13.539 88.941,14.694C88.443,15.849 87.765,16.856 86.905,17.716C86.045,18.576 85.042,19.254 83.896,19.751C82.749,20.249 81.519,20.497 80.204,20.497C78.889,20.497 77.654,20.249 76.499,19.751C75.344,19.254 74.337,18.576 73.477,17.716C72.617,16.856 71.939,15.849 71.442,14.694C70.944,13.539 70.696,12.313 70.696,11.015ZM76.638,11.015C76.638,11.503 76.731,11.967 76.916,12.405C77.102,12.844 77.355,13.223 77.675,13.543C77.995,13.864 78.37,14.116 78.8,14.302C79.23,14.487 79.698,14.58 80.204,14.58C80.693,14.58 81.152,14.487 81.582,14.302C82.012,14.116 82.387,13.864 82.707,13.543C83.028,13.223 83.28,12.844 83.466,12.405C83.651,11.967 83.744,11.503 83.744,11.015C83.744,10.509 83.651,10.041 83.466,9.611C83.28,9.181 83.028,8.806 82.707,8.486C82.387,8.165 82.012,7.913 81.582,7.727C81.152,7.542 80.693,7.449 80.204,7.449C79.698,7.449 79.23,7.542 78.8,7.727C78.37,7.913 77.995,8.165 77.675,8.486C77.355,8.806 77.102,9.181 76.916,9.611C76.731,10.041 76.638,10.509 76.638,11.015Z" />
        <path d="M89.687,11.015C89.687,9.7 89.935,8.465 90.433,7.31C90.93,6.155 91.608,5.148 92.468,4.288C93.328,3.428 94.335,2.75 95.49,2.252C96.645,1.755 97.88,1.506 99.195,1.506C100.51,1.506 101.74,1.755 102.887,2.252C104.033,2.75 105.036,3.428 105.896,4.288C106.756,5.148 107.434,6.155 107.932,7.31C108.429,8.465 108.678,9.7 108.678,11.015C108.678,12.313 108.429,13.539 107.932,14.694C107.434,15.849 106.756,16.856 105.896,17.716C105.036,18.576 104.033,19.254 102.887,19.751C101.74,20.249 100.51,20.497 99.195,20.497C97.88,20.497 96.645,20.249 95.49,19.751C94.335,19.254 93.328,18.576 92.468,17.716C91.608,16.856 90.93,15.849 90.433,14.694C89.935,13.539 89.687,12.313 89.687,11.015ZM101.475,6.431L96.283,6.431L96.283,9.46L97.283,9.46L97.283,15.763L101.475,15.763L101.475,6.431Z" />
    </g>
);
