
export const spinner = {
    baseStyle: props => {
        return {
            w: "16px",
            h: "16px",
            fill: "bs.gray.200",
            display: "inline-block",
            mx: "auto", my: "auto",
        }
    },
    sizes: {
        md: {
            w: "24px", h: "24px",
        },
        lg: {
            w: "32px", h: "32px",
        },
        xl: {
            w: "64px", h: "64px",
            fill: "bs.gray.100",
        }
    }
};
