import { useColorModeValue, useToken } from "@chakra-ui/react";
import { memo } from "react";
import { useMemo } from "react";

const SVG = ({ id, rl, rr, al, ar, colors }) => (
    <svg
        ref={id}
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        strokeLinejoin="round"
        strokeMiterlimit="2"
        clipRule="evenodd"
        viewBox="0 0 58 20"
        style={{ whiteSpace: "preserve-spaces" }}
    >
        <defs>
            <mask id="Mask-2">
                <path
                    fill="white"
                    d="M0 28c15.464 0 28-12.536 28-28S15.464-28 0-28-28-15.464-28 0s12.536 28 28 28zM0 8A8 8 0 100-8 8 8 0 000 8z"
                    transform="translate(10 10)"
                ></path>
            </mask>
            <mask id="Mask-1">
                <path
                    fill="white"
                    d="M0 28c15.464 0 28-12.536 28-28S15.464-28 0-28-28-15.464-28 0s12.536 28 28 28zM0 8A8 8 0 100-8 8 8 0 000 8z"
                    transform="translate(10 10)"
                ></path>
            </mask>
            <clipPath id="mainClip">
                <rect
                    width="58"
                    height="20"
                    rx="10"
                    transform="translate(27.5,9.5) translate(-27.5,-9.5)"
                ></rect>
            </clipPath>
        </defs>

        <g clipPath="url(#mainClip)">
            <rect width="58" height="20" fill={colors[0]} />
            <circle cx="10" cy="10" r="8.5" fill={colors[1]} />
            <circle cx="48" cy="10" r="8.5" fill={colors[1]} />
            <g transform="translate(15,10) translate(-15,-10)">
                <g
                    mask="url(#Mask-2)"
                    transform="translate(10,10) translate(-10,-10)"
                >
                    <circle
                        id="left_band"
                        r={rl}
                        fill="#46413F"
                        clipRule="evenodd"
                        fillRule="evenodd"
                        strokeLinejoin="round"
                        strokeMiterlimit="2"
                        transform="translate(10,10)"
                    />
                </g>
                <g
                    mask="url(#Mask-1)"
                    transform="translate(48,10) translate(-10,-10)"
                >
                    <circle
                        id="right_band"
                        r={rr}
                        fill="#46413F"
                        clipRule="evenodd"
                        fillRule="evenodd"
                        strokeLinejoin="round"
                        strokeMiterlimit="2"
                        transform="translate(10,10)"
                    />
                </g>
            </g>
            <g transform="translate(29,10) translate(-29,-10)">
                <path
                    id="shadow"
                    d="M64,15C64,9.481,59.519,5,54,5L16,5C10.481,5,6,9.481,6,15C6,20.519,10.481,25,16,25L54,25C59.519,25,64,20.519,64,15ZM51.666,6.211C47.777,7.243,44.908,10.789,44.908,15C44.908,19.211,47.777,22.757,51.666,23.789L18.334,23.789C22.223,22.757,25.092,19.211,25.092,15C25.092,10.789,22.223,7.243,18.334,6.211L51.666,6.211ZM16,8C19.863,8,23,11.137,23,15C23,18.863,19.863,22,16,22C12.137,22,9,18.863,9,15C9,11.137,12.137,8,16,8ZM54,8C57.863,8,61,11.137,61,15C61,18.863,57.863,22,54,22C50.137,22,47,18.863,47,15C47,11.137,50.137,8,54,8Z"
                    fillOpacity="0.2"
                    transform="translate(29,10) translate(-35,-15)"
                />
                <path
                    id="right_wheel"
                    d="M54,7C58.415,7,62,10.585,62,15C62,19.415,58.415,23,54,23C49.585,23,46,19.415,46,15C46,10.585,49.585,7,54,7ZM54.868,10.832L54.868,9.14C56.39,9.363,57.725,10.167,58.64,11.319L57.176,12.164L58.044,13.668L59.509,12.822C59.776,13.496,59.923,14.231,59.923,15C59.923,15.769,59.776,16.504,59.509,17.178L58.044,16.332L57.176,17.836L58.64,18.681C57.725,19.833,56.39,20.636,54.868,20.86L54.868,19.168L53.132,19.168L53.132,20.86C51.61,20.637,50.275,19.833,49.36,18.681L50.824,17.836L49.956,16.332L48.491,17.178C48.224,16.504,48.077,15.769,48.077,15C48.077,14.231,48.224,13.496,48.491,12.822L49.956,13.668L50.824,12.164L49.36,11.319C50.275,10.167,51.61,9.364,53.132,9.14L53.132,10.832L54.868,10.832Z"
                    fill="#EFEEE0"
                    transform={`translate(48,10) rotate(${ar}) translate(-54,-15)`}
                />
                <path
                    id="left_wheel"
                    d="M16,7C20.415,7,24,10.585,24,15C24,19.415,20.415,23,16,23C11.585,23,8,19.415,8,15C8,10.585,11.585,7,16,7ZM16.868,10.832L16.868,9.14C18.39,9.363,19.725,10.167,20.64,11.319L19.176,12.164L20.044,13.668L21.509,12.822C21.776,13.496,21.923,14.231,21.923,15C21.923,15.769,21.776,16.504,21.509,17.178L20.044,16.332L19.176,17.836L20.64,18.681C19.725,19.833,18.39,20.636,16.868,20.86L16.868,19.168L15.132,19.168L15.132,20.86C13.61,20.637,12.275,19.833,11.36,18.681L12.824,17.836L11.956,16.332L10.491,17.178C10.224,16.504,10.077,15.769,10.077,15C10.077,14.231,10.224,13.496,10.491,12.822L11.956,13.668L12.824,12.164L11.36,11.319C12.275,10.167,13.61,9.364,15.132,9.14L15.132,10.832L16.868,10.832Z"
                    fill="#EFEEE0"
                    transform={`translate(10,10) rotate(${al}) translate(-16,-15)`}
                />
            </g>
        </g>
    </svg>
);

export const Cassette = memo(({ id, length, duration, pos, running }) => {

    const colorPair = useMemo(() => (
        running
        ? ["bs.bright.green", "bs.green"]
        : ["bs.gray.400", "bs.gray.400"]
    ), [running]);

    const modeColors = useColorModeValue(colorPair, colorPair);

    const colors = useToken("colors", modeColors);

    const
    minR = 10, maxR = 26, maxD = 30 * 60,

    maxBandW = maxR - minR,

    bandW = maxBandW * (length / maxD),

    rl = minR + (bandW * (1 - pos)),
    rr = minR + (bandW * pos),

    startAL = -15, startAR = 15,

    turns = Math.max(duration / 6, 1),

    al = startAL - (360 * pos * turns),
    ar = startAR - (360 * pos * turns);

    // console.log(`dur:`, length);

    // console.log("Cassette: pos, duration, rl, rr:", pos, duration, rl, rr);

    return (
        <SVG {...{ id, rl, rr, al, ar, colors }} />
    )
});
