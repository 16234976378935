import { Box, useStyleConfig, Link, useToken } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

const icon = fill => ({
    parent: <svg viewBox="0 0 24 12" width="24" height="12" fill={fill}>
        <circle cx="6" cy="10" r="2" />
        <circle cx="14" cy="10" r="2" />
    </svg>
})

export const Card = ({ entry, size, variant, link }) => {

    const styles = useStyleConfig("Card", { variant, size });

    // console.log(variant);

    const fill = useToken("colors", styles.color);

    return (
        link
        ? <Link sx={styles} as={RouterLink} to={entry.href}>
            { icon(fill)[variant] }
            { entry.title }
        </Link>
        : <Box __css={styles}>
            { icon(fill)[variant] }
            { entry.title }
        </Box>
    )
};
