import { shuffle } from "lodash";
import { Fragment } from "react";
import { entities2unicode, getRandomValue, objType, round } from "../../../shed";

export const formatTime = time => Object.entries(time).reduce((str, [key, value]) => {
    let d = value.toString();
    if (["hh", "mm", "ss"].includes(key)) {
        if (value < 10) d = "0" + value;
        if (key !== "hh") d = ":" + d;
        return str + d;
    }
    return str + "." + (
        value < 10
        ? "00" + d
        : value < 100
        ? "0" + d
        : d
    );
}, "");

const parseActor = ({ str, actors }) => {
    // console.log("parseActor: str, actors:", str, actors);
    const findActor = name => 
        actors.find(actor => [...(actor.syn || []), actor.name].includes(
            name.replace(/^'|'$/g, "")
        ));

    const template = str.substr(1);

    let [actor, label] = /^[^:]+:[^:]+$/.test(template)
    ? [findActor(template.split(":")[0]), template.split(":")[1].replace(/^'|'$/g, "")]
    : [findActor(template)];

    if (!label) label = actor.name;


    return { actor, label };
};

export const parseString = (text, actors) => {
    // @Швондер
    // @'Филипп Филиппович'
    // @фф:'Филиппа Филипповича'
    // @'Филипп Филиппович':'Филиппа Филипповича'
    return text.split(/(@[^\s':\.,;!?]+)(\s|[\.,;!?]+|$)|(@'[^']+')(\s|$)|(@[^\s':]+:[^\s']+)|(@[^\s':]+:'[^']+')|(@'[^']+':'[^']+')/g)
    .filter(s=>s)
    .map(str => (
        str[0]==="@"
        ? parseActor({ str, actors })
        : parseUrls(str)
    ))
    .flat(1);

};

const defaultImageSize = { width: 150 };

export const parseUrls = str => {
    const arr = str.split(/(http[s]*:\/\/[^\s]+[\w\/]|mailto:[^\s]+[\w])/i).filter(s=>s);
    // console.log(`parseUrls:`, arr);
    return arr
    .map((s, i) => /^http[s]*:\/\//i.test(s)
    ? <a target="_blank" rel="noopener noreferrer" key={`url${i}`} href={s}>{s}</a>
    : /^mailto:/i.test(s)
    ? <a target="_blank" rel="noreferrer" key={`url${i}`} href={s}>{s.replace(/^mailto:/i, "").replace(/\?subject=.*$/, "")}</a>
    : s);
};

const isEmoji = s => /\p{Extended_Pictographic}/u.test(s);
const isZWJ = s => /\u200D|\uFE0F/.test(s);
const unicodeChar = c => {
    // https://stackoverflow.com/questions/48009201/how-to-get-the-unicode-code-point-for-a-character-in-javascript
    const hex = c.codePointAt(0).toString(16);
    return "\\u" + "0000".substring(0, 4 - hex.length) + hex;
};

export const parseEmoji = (str, emojiStyle) => {
    const arr = str.split(/([\p{Extended_Pictographic}]+)/u).filter(s=>s),
    output = []; let emoji = [];
    arr.forEach((s, i, arr) => {
        if (isEmoji(s) || isZWJ(s)) {
            emoji.push(s);
            if ( i===arr.length-1 || (!isEmoji(arr[i+1]) && !isZWJ(arr[i+1])) ) {
                // console.log(`is zwj after ${s}:`, isZWJ(arr[i+1]));
                output.push(<span key={i} {...emojiStyle}>{emoji.join("")}</span>);
                emoji = [];
            }
            return;
        }
        output.push(s);
    });
    // console.log(`parseEmoji: arr:`, arr);
    // console.log(`parseEmoji: output:`, output);
    return output;
}

const parseImageString = (str, directory) => {
    const matched = str.match(/^!\(([^\)]+)\)$/);
    if (!matched) {
        console.log(`parseImageString: ${str} not an image`);
        return null;
    }
    const parts = matched[1].split(/\s+/);

    const src = /^[a-z]/i.test(parts[0])
    ?  `${directory}/${parts[0]}` : parts[0];

    const img = { src };

    if (parts.length === 1) return { ...img, ...defaultImageSize };

    if (parts[1]) img.width = parseInt(parts[1]);
    if (parts[2]) img.height = parseInt(parts[2]);
    if (parts[3]) img.alt = parts[3];

    return img;
};

export const parseText = (text, actors, directory) => {

    // console.log(`parseText: text:`, text);

    // console.log("parseText got actors:", actors);

    const rx = {
        time: /^[-+]*(\d*\.)?\d+$/,
        actor: /^[^:]+:$/,
        status: /^\/.+/,
        typing: /^\.\.\.(\d*\.)?\d+$/,
        remarque: /^\[[^\]]+\]$/,
        image: /^!\([^\)]+\)$/,
        comment: /^#/,
    };

    const ex = {
        actor: /\\:$/,
    };

    const test = (line, key) => rx[key].test(line) && !ex[key]?.test(line);

    const match = line => Object.keys(rx).find(key => rx[key].test(line) && !ex[key]?.test(line));

    const arr = text.split(/\r?\n/).filter(s=>!!s);

    // console.log(arr);

    let actor, action, time = 0, relTime, item, content,
    params = {};

    const flush = () => {
        if (item) {
            // console.log(`%c${item.actor.toUpperCase()}: ${item.content}`, "color:orange");
            parsed.push(item);
            item = null;
            params = {};
        }
    }

    const parsed = [];

    arr.forEach((line, index) => {

        if (test(line, "comment")) return;

        if (test(line, "time") && item && item.typing) {
            time = round(time + item.typing, 3);
        }

        if (
            [
                "time", "actor", "status",
                "remarque",
                "typing",
                "image",
            ].some(key => test(line, key)) ||
            (item && item.action !== "line")
        ) {
            // console.log(`item before flush:`, item);
            flush();
        }

        if (test(line, "time")) {
            time = round(time + parseFloat(line), 3);
            relTime = parseFloat(line);
            return;
        }

        if (test(line, "actor")) {
            const actorName = line.replace(/:$/, "");
            actor = actors.find(a => a.name === actorName || a.syn?.includes(actorName)) || { name: actorName };
            /* if (actorName!==actor) {
                console.log("parseText: actorName:", actorName);
                console.log("parseText: actor:", actor);
            } */
            return;
        }

        // console.log(match(line));

        switch (match(line)) {
            case "remarque": {
                action = "remarque";
                content = line.replace(/\[|\]/g, "");
                if (match(content)==="image") content = parseImageString(content, directory);
                break;
            }
            case "status": {
                action = "status";
                content = line.substr(1);
                break;
            }
            case "typing": {
                const
                param = "typing",
                value = round(parseFloat(line.replace(/^\.\.\./, "")), 3);
                params = {...params, [param]: params[param] ? params[param] + value: value};
                action = "line";
                content = null;
                break;
            }
            case "image": {
                action = "image";
                content = parseImageString(line, directory);
                break;
            }
            default: {
                action = "line";
                let parsed = line.replace(/\\/g, "");
                if (item?.action === "line" && item.content) {
                    // console.log("parseText: prev line was line");
                    // console.log(`item:`, item);
                    content = `${item.content}\n${parsed}`;
                } else {
                    content = parsed;
                }
            }
        }

        item = {
            time: time < 0 ? 0 : time, relTime: relTime===undefined ? 0 : relTime, action, ...params,
            content: entities2unicode(content)
        };

        if (action !== "remarque") item.actor = actor;

        // if (action === "typing") time = round(time + content, 3);

        // prevAction = action;

        if (index === arr.length - 1) flush();


    });

    // console.log(parsed);

    return parsed;
};

export const parseActors = (actors) => {
    // console.log("parse actors", actors.map(a=>a.name));
    return actors.map(
        (actor, index) => ({...actor, position: index % 2 === 0 ? "left" : "right", color: index + 1 })
    )
};
