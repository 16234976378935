import { Box, useStyles, Image as ChakraImage } from "@chakra-ui/react";
import { useRef } from "react";
import Actor from "./Actor";

const maxWidths = {
    base: 280, sm: 340, md: 340, lg: 420, xl: 620,
};

const getSizes = ({ width: srcWidth, height: srcHeight }) => {
    const widths = [], heights = [];
    Object.values(maxWidths).forEach((maxWidth, i) => {
        let width = srcWidth, height = srcHeight;
        if (srcWidth > maxWidth) {
            width = maxWidth;
            if (srcHeight) {
                const ratio = srcWidth / maxWidth;
                height = (srcHeight / ratio);
            }
        }
        widths.push(width + "px");
        if (height) heights.push(height + "px");
    }, []);    

    // console.log(widths, heights);
    return [widths, heights];
};

export const Image = ({ imageObject, style, setNode }) => {

    const
    element = useRef(),
    [widths, heights] = getSizes(imageObject),
    imageProps = {
        ...imageObject,
        width: widths,
        height: heights.length > 0 ? heights : null
    };

    return (
        <ChakraImage
            {...style}
            {...imageProps}
            ref={element}
            onLoad={setNode?()=>{setNode(element.current)}:null}
        />
    )
};

const ImageLine = ({ action, setNode }) => {

    const { line: style } = useStyles();

    const { actor: { position } } = action;

    /* const element = useRef();
    const [widths, heights] = getSizes(action.content);
    const imageProps = {
        ...action.content,
        width: widths,
        height: heights.length > 0 ? heights : null
    }; */

    // console.log(imageProps);

    return (<>
        <Box
            name = "image"
            {...style.box}
            flexDirection = {{ left: "row", right: "row-reverse" }[position]}
            flexWrap = "wrap"
        >
            <Actor actor={action.actor}
                style={{
                    avatar: { size: "sm", mx: "6px", mt: "3px" },
                    display: "inline-flex"
                }}
            />

            <Image {...{ imageObject: action.content, style: style.image, setNode }} />

            {/* <ChakraImage
                {...style.image}
                {...imageProps}
                ref={element}
                onLoad={setNode?()=>{setNode(element.current)}:null}
            /> */}

        </Box>

    
    </>)
};

export default ImageLine;
