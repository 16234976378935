// import { mode } from "@chakra-ui/theme-tools"

const iPadOS = /iPad/.test(navigator.userAgent) ||
(navigator.platform === "MacIntel" && navigator.standalone !== undefined);

export const player = {
    parts: ["device", "chat", "line", "controls", "progress"],
    baseStyle: props => {
        // console.log("baseStyle request");
        return {
        device: {
            borderRadius: [0, 0, 0, 0, 12],
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            height: "-webkit-fill-available",
        },
        chat: {
            flexGrow: 1,
            overflowY: "scroll",
            h: "1px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            // scrollBehavior: "smooth",
        },
        line: {
            box: {
                display: "flex",
                my: "3em",
                px: "10px",
                w: "100%",
                maxWidth: "70em",
                // flexGrow: "1",
            },
            message: {
                whiteSpace: "pre-line",
                flexWrap: "wrap",
                lineHeight: "24px",
                fontSize: "16px",
                // display: "inline-flex",
                maxWidth: ["67%", "60%", "67%", null, null, "50%"],
                borderRadius: "19px",
                padding: "6px 15px 8px 16px",
            },
            emoji: {
                fontSize: "24px",
                lineHeight: "20px",
                position: "relative",
                top: "3px",
                display: "inline-block",
            },
            remarque: {
                whiteSpace: "pre-line",
                fontSize: "15px",
                lineHeight: "24px",
                textAlign: "center",
                color: "#888",
                width: "100%",
                my: "1em",
                px: "20px"
                // display: "flex",
                // flexWrap: "wrap",
                // alignItems: "center",
                // justifyContent: "center",
            },
            image: {
                borderRadius: "20px",
                border: `2px solid`,
                borderColor: "#00000007",
                backgroundColor: "#00000007",
            }
        },
        controls: {
            display: "flex",
            h: "60px",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "20px",
            bg: "bs.gray.700",
            px: "40px",
        },
        progress: {
            display: "flex",
            p: iPadOS
            ? "20px 40px 50px 40px"
            : "12px 40px 12px 40px",
            // py: "12px",
            // p: "20px 40px 60px 40px",
            // height: "60px",
            alignItems: "center"
        },
    } },
    variants: {
        
    }
};
