import { useStyles, Box, } from "@chakra-ui/react";
import { useState } from "react";
import { useEffect } from "react";
import { memo } from "react";
import { cap } from "../../../shed";
import { useStore } from "../../../store";
import Line from "../chat/Line";
import Remarque from "../chat/Remarque";
import Status from "../chat/Status";
import Typing from "../chat/Typing";
import shallow from "zustand/shallow";
import ImageLine from "../chat/Image";
import { useMeasure } from "react-use";

const actions = {
    Typing, Line, Status, Remarque, Image: ImageLine,
}

/* const scrollToEnd = (node, t) => {
    if (node.scrollHeight > node.clientHeight) {
        smoothScroll(node, node.scrollHeight - node.clientHeight, t);
        // t = setTimeout(() => { scrollToEnd(node, t); }, 1);
    }
};

const smoothScroll = (node, d, t) => {
    if (node.scrollTop < d) {
        node.scrollTop += 5;
        t = setTimeout(() => { smoothScroll(node, d, t); }, 1);
    }
} */

const Chat = memo(() => {
    const { chat: style } = useStyles();

    const {
        feed, actors, chat, running, startTime
    } = useStore(state => {

        const { feed, actors, chat, running, startTime } = state.player;
        
        return { feed, actors, chat, running, startTime };
        /* {
            feed: state.player.feed,
            actors: state.player.actors,
            chat: state.player.chat,
            running: state.player.running,
            startTime: state.player.startTime,
        } */
    }, shallow);

    /* useEffect(() => {
        console.log("Chat: feed:", feed);
    }, [feed]); */
    
    /* useEffect(() => {
        console.log("Chat:", chat);
        // return () => { console.log("%cChat unmount", "color:orange"); };
    }, [chat]); */


    const [node, setNode] = useState();
    const [contentNode, { height }] = useMeasure();

    useEffect(() => {
        if (node && height) {
            // console.log(`height changed:`, height);
            node.scrollIntoView({ behavior: "smooth" });
        }
    }, [node, height]);


    if (!feed) return null;

    return (
        <Box
            __css={style}
            data-name="chat frame"
            // ref={node => setParent(node)}
        >
                <Box w="100%" ref={contentNode}>
                    { feed.map((item, i) => {
                        const Component = actions[cap(item.action)];
                        const props = {
                            actors, action: item, key: i, index: i, chat, running,
                            startTime
                        };
                        return (
                            <Component {...props } />
                        )
                    }) }
                </Box>
                <div ref={node=>setNode(node)} />
                
        </Box>
    );
});

export default Chat;
