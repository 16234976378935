import { item } from "./item/style";
import { layout } from "./layout/style";
import { menu } from "./menu/style";
import { player } from "./player/style";
import { settings } from "./settings/style";
import { sidebar } from "./sidebar/style";
import { spinner } from "./spinner/style";
import { card, cassette } from "./thumbnail/style";

const components = {
    Layout: layout,
    Sidebar: sidebar,
    PanelMenu: menu,
    Item: item,
    Player: player,
    PanelSettings: settings,
    Cassette: cassette,
    Card: card,
    SpinnerWheel: spinner,
};

export default components;
