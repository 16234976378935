import { Box, useColorModeValue, useStyles } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { memo } from "react";
import { useEffect } from "react";
import { useMemo, useRef, useState } from "react";
import { useTraceUpdate } from "../../../shed";
import Actor from "./Actor";
import Dot from "./Dot";

const Typing = memo(({ action, setNode, running, chat, index, startTime }) => {

    const { actor: { position } } = action;
    const { line: style } = useStyles();
    const bg = useColorModeValue(
        `chat.line.light${action.actor.color}`,
        `chat.line.dark${action.actor.color}`
    );

    // console.log(chat);
    const
        [show, set] = useState(true),
        /* [hold, setHold] = useState(() => {
            console.log("Typing: set initial hold value");
            console.log("action:", action);
            console.log("chat:", chat);
            return chat[index+1].action === "typing"
        }), */
        [hold, setHold] = useState(chat[index+1]?.action === "typing"),
        timeout = useMemo(() => (
            (
                startTime > action.time
                ? action.content - (startTime - action.time)
                : action.content
            ) * 1000
        ), [action, startTime]),
        t = useRef(),
        t2 = useRef();

    useEffect(() => {
        if (t.current) { clearTimeout(t.current); }
        if (!running) return;
        t.current = setTimeout(() => {
            set(false);
        }, timeout);
        return () => { clearTimeout(t.current) }
    }, [timeout, running]);

    useEffect(() => {
        if (t2.current) { clearTimeout(t2.current); }
        if (!running) return;
        if (hold) {
            t2.current = setTimeout(() => {
                setHold(false);
            }, timeout + (chat[index+1].relTime) * 1000);
        }
        return () => { clearTimeout(t2.current) }
    }, [hold, running, chat, index, action, timeout]);

    // useEffect(()=>{ console.log("Typing render") });

    // useTraceUpdate({ action, setNode, running, chat, index, startTime, show, hold, timeout }, "Typing");

    // console.log(`Typing: action after ${index}:`, chat[index+1].action);

    if (!running) return null;

    return (
        show
        ? <Box ref={setNode?node=>{setNode(node)}:null}
            name="typing"
            {...style.box }
            flexDirection = {{ left: "row", right: "row-reverse" }[position]}
        >
            <Actor actor={action.actor} style={
                { avatar: { size: "sm", mx: "6px", mt: "3px" } }
            } />
            <Box
                data-name="message"
                { ...{...style.message, bg } }
            >
                <Dot delay={0} />
                <Dot delay={0.1} />
                <Dot delay={0.2} />
            </Box>
        </Box>

        : hold

        ? <Box ref={setNode?node=>{setNode(node)}:null}
            data-name= "empty typing"
            {...style.box }
        >
            <Box { ...{...style.message, bg: "transparent" } } />
                
        </Box>

        : null
    );

});

export default Typing;
