import {
    Box,
    Button,
    Flex,
    CircularProgress,
    Progress,
    useToken,
} from "@chakra-ui/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLongPress } from "react-use";
import { getPanelConfig } from "../size";
import { objType, round, urlize } from "../shed";
import "./test.css";
import Cassette from "../3/components/thumbnail/cassette";
import { animate, motion, useMotionValue, useSpring } from "framer-motion";
import { useS } from "../store";

const toggleUserSelect = () => {
    document.body.classList.toggle("nouserselect");
};

const userSelectOff = () => {
    document.body.classList.add("nouserselect");
};

const userSelectOn = () => {
    document.body.classList.remove("nouserselect");
};

const TestA = () => {
    const [node, setNode] = useState();

    const [msg, set] = useState("");

    const [touch, setTouch] = useState("");

    const [counting, setCounting] = useState(false);

    const t = useRef(null);
    const [count, setCount] = useState(0);

    const tick = useCallback(() => {
        // console.log(count);
        setCount(i => (i < 100 ? i + 4 : i));
    }, []);

    const start = useCallback(() => {
        console.log("start");
        setCount(0);
        t.current = setInterval(tick, 19);
    }, [tick]);

    const stop = useCallback(() => {
        clearInterval(t.current);
        t.current = null;
        // setCount(0);
    }, []);

    // const run = useCallback(() => {}, )

    const onLongPress = e => {
        // console.log("long press");
        // document.body.style.userSelect = "none !important";
        set(`longPress ${count} ${Date.now()}`);
    };

    const defaultOptions = {
        isPreventDefault: true,
        delay: 500,
    };

    const longPress = useLongPress(onLongPress, defaultOptions);

    const onTouchStart = e => {
        start();
        // setCount(100);
        setTouch(`touch start ${node.nodeName}`);
        userSelectOff();
        longPress.onTouchStart(e);
    };

    const onTouchEnd = e => {
        longPress.onTouchEnd(e);
        stop();
        setTouch(`touch end ${node.nodeName}`);
        userSelectOn();
        // setUs("auto");
    };

    const onMouseDown = e => {
        // document.body.style.setProperty("user-select", "none", "important");
        userSelectOff();
        console.log(document.body.style.userSelect);
        longPress.onMouseDown(e);
    };
    const onMouseUp = e => {
        // document.body.style.setProperty("user-select", "auto", "important");
        userSelectOn();
        console.log(document.body.style.userSelect);
        longPress.onMouseUp(e);
    };
    const onMouseLeave = e => {
        longPress.onMouseLeave(e);
        // document.body.style.setProperty("user-select", "auto", "important");
        userSelectOn();
        console.log(document.body.style.userSelect);
    };

    return (
        <>
            <Flex direction="column" ref={node => setNode(node)}>
                <Button
                    m="40px"
                    className="x"
                    {...{
                        ...longPress,
                        onTouchStart,
                        onTouchEnd,
                        onMouseDown,
                        onMouseUp,
                        onMouseLeave,
                    }}
                >
                    test
                </Button>
                <Box m="40px">{msg}</Box>
                <Box m="40px">{touch}</Box>
                <Counter count={count} />
            </Flex>
            And some text here
        </>
    );
};

const Counter = ({ count }) => {
    const [node, set] = useState();

    useEffect(() => {
        if (!node) return;
        console.log(node);
    }, [node]);

    return (
        <Box ref={node => set(node)}>
            <CircularProgress value={count} className="noanimation" />
        </Box>
    );
    // <Box m="40px">{count}</Box>
};

const TestTime = () => {
    const sec = 1.205;

    const sec2timeV = sec => {
        const div = (a, b) => ({
            int: Math.floor(a / b),
            rem: round(a % b, 3),
        });
        console.log(`${sec} seconds:`);
        let { int: hh, rem: rm } = div(sec, 3600);
        console.log(`${hh} hours`);
        console.log(`${rm} seconds remained`);
        let { int: mm, rem: rs } = div(rm, 60);
        console.log(`${mm} minutes`);
        console.log(`${rs} seconds remained`);
        let { int: ss, rem } = div(rs, 1);
        const ms = rem * 1000;
        console.log(`${ss} seconds`);
        console.log(`${rem} seconds remained`);
        console.log(`${ms} milliseconds`);

        return { hh, mm, ss, ms };
    };
    const sec2time = sec => {
        const div = (a, b) => ({
            int: Math.floor(a / b),
            rem: round(a % b, 3),
        });
        console.log(`${sec} seconds:`);
        const { int: hh, rem: rm } = div(sec, 3600),
            { int: mm, rem: rs } = div(rm, 60),
            { int: ss, rem } = div(rs, 1),
            ms = rem * 1000;
        return { hh, mm, ss, ms };
    };

    console.log(sec2time(sec));

    return <Box>test</Box>;
};

const TestPanels = () => {
    const panels = getPanelConfig();

    // console.log(panels);

    Object.entries(panels).forEach(([size, panel]) => {
        console.log(size.toUpperCase());
        console.log(JSON.stringify(panel, null, 2));
    });

    return <Box>panels</Box>;
};

const TestColors = () => {

    return (
        <Box p="40px">
            <Box m="20px">
                <Cassette entry={{ id: "06eb82f1-b5ae-4570-841b-9e85e56ad2ce", title: "test norm" }} />
            </Box>
            <Box m="20px">
                <Cassette entry={{ id: "27c68ea0-5e0a-4dc2-bede-cc5769a9f00c", title: "test sm" }} size="md" />
            </Box>
            <Box m="20px">
                <Cassette entry={{ id: "76db3336-03c2-42c8-a309-94f751207e07", title: "test sm" }} size="md" variant="active" />
            </Box>
        </Box>
    )
};

const TestScroll = () => {

    const [node, setNode] = useState();

    const MBox = motion(Box);

    const x = useMotionValue(0);
    const s = useSpring(x);

    useEffect(() => {
        /* if (node) {
            node.scrollTop = node.scrollHeight - node.clientHeight;
        } */
        x.onChange(v => { console.log(v); });
        x.set(100);
    }, [x]);


    return (
        <Box overflowY="scroll" h="200px" w="200px" ref={node=>setNode(node)}>
            {/* { x.current } */}
            { (new Array(60).fill(1)).map((e, i)=>(
                <Box key={i}>element {i+1}</Box>
            )) }
        </Box>
    )
};

const TestHyph = () => {

    // console.log(window.Hyphenopoly.hyphenators["ru"]);

    const hyphenator = useS("hyphenator");

    // console.log(objType(hyphenator));

    console.log(hyphenator("длинноеслово"));

    /* window.Hyphenopoly.hyphenators["ru"].then(
        hyphenator => {
            console.log(hyphenator("Дома Левиных и Щербацких были старыедворянскиемосковские дома и всегда были между собою в близких и дружеских отношениях.", ".hyph"));
        }
    );
    window.Hyphenopoly.hyphenators["en-us"].then(
        hyphenator => {
            console.log(hyphenator("Hyphenation", ".pipe"));
        }
    ); */

    return (
        <>
        <p style={{ width: "30em" }}>
        {hyphenator(`Дома Левиных и Щербацких были старые дворянские московские дома и всегда были между собою в близких и дружеских отношениях. Связь эта утвердилась еще больше во время студенчества Левина. Он вместе готовился и вместе поступил в университет с молодым князем Щербацким, братом Долли и Кити. В это время Левин часто бывал в доме Щербацких и влюбился в дом Щербацких. Как это ни странно может показаться, но Константин Левин был влюблен именно в дом, в семью, в особенности в женскую половину семьи Щербацких. Сам Левин не помнил своей матери, и единственная его сестра его была старше его, так что в доме Щербацких он в первый раз увидел ту самую среду старого дворянского, образованного и честного семейства, которой он был лишен смертью отца и матери. Все члены этой семьи, в особенности женская половина, представлялись ему покрытыми какою-то таинственною, поэтическою завесой, и он не только не видел в них никаких недостатков, но под этой поэтическою, покрывавшею их завесой предполагал самые возвышенные чувства и всевозможные совершенства.`)}
        </p>
        <p lang="ru" className="hyph">
        Во время своего студенчества он чуть было не влюбился в старшую, Долли, но ее вскоре выдали замуж за Облонского. Потом он начал было влюбляться во вторую. Он как будто чувствовал, что ему надо влюбиться в одну из сестер, только не мог разобрать, в какую именно. Но и Натали, только что показалась в свет, вышла замуж за дипломата Львова. Кити еще была ребенок, когда Левин вышел из университета. Молодой Щербацкий, поступив в моряки, утонул в Балтийском море, и сношения Левина с Щербацкими, несмотря на дружбу его с Облонским, стали более редки. Но когда в нынешнем году, в начале зимы, Левин приехал в Москву после года в деревне и увидел Щербацких, он понял, в кого из трех ему действительно суждено было влюбиться.
        </p>
        <p lang="ru" className="hyph">
        Казалось бы, ничего не могло быть проще того, чтобы ему, хорошей породы, скорее богатому, чем бедному человеку, тридцати двух лет сделать предложение княжне Щербацкой; по всем вероятиям, его тотчас признали бы хорошею партией. Но Левин был влюблен, и поэтому ему казалось, что Кити была такое совершенство во всех отношениях, такое существо превыше всего земного, а он такое земное низменное существо, что не могло быть и мысли о том, чтобы другие и она сама признали его достойным ее.
        </p>
        </>
    )
};

export default TestHyph;
