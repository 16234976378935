import { cloneDeep, merge } from "lodash";
import { useCallback } from "react";
import produce from "immer";
import create from "zustand";
import { devtools } from "zustand/middleware"
import { cookieKey } from "../boot";
import { cap, getDeepKeyValue, objInObj, round } from "../shed";

// https://stackoverflow.com/questions/46957194/javascript-es6-spread-operator-on-undefined

const createUserSlice = set => ({
    user: {},
    updateUser: props => set(state => ({ user: { ...state.user, ...props } })),
    updatePrefs: props => set(state => ({
        user: {
            ...state.user, prefs: {...state.user.prefs, ...props }
        }
    })),
    markAsRead: id => set(state => {
        if (state.user.prefs.read && state.user.prefs.read.includes(id)) {
            console.log("this item already marked as read");
            return;
        }
        const read = state.user.prefs.read ? [...state.user.prefs.read] : [];
        read.push(id);
        return {
            user: {
                ...state.user,
                prefs: {
                    ...state.user.prefs,
                    read
                }
            }
        }
    }),
});

const createAppSlice = set => ({
    app: {
    },
    updateApp: (props) => set(state => ({ app: { ...state.app, ...props } })),
    mergeApp: (props) => set(state => {
        return ({ app: merge({}, state.app, props) });
    }),
    toggleDrawer: name => set(state => {
        const drawers = cloneDeep(state.app.drawers);
        if (drawers[name].isOpen) {
            drawers[name].isOpen = false;
        } else {
            Object.keys(drawers).forEach(key => { drawers[key].isOpen = key === name ? true : false });
        }
        return { app: {...state.app, drawers } };
    }),
    openDrawer: name => set(state => {
        const drawers = cloneDeep(state.app.drawers);
        Object.keys(drawers).forEach(key => { drawers[key].isOpen = key === name ? true : false });
        return { app: {...state.app, drawers } };
    }),
    closeDrawers: () => set(state => {
        return { app: {
            ...state.app,
            drawers: Object.entries(state.app.drawers)
                .reduce((drawers, [name, value]) => ({...drawers, [name]: {...value, isOpen: false } }), {})
        } };
    }),
});

const getSetters = (set, slice, keys) => (
    keys.reduce((obj, key) => ({...obj, [`set${cap(key)}`]: props => set(produce(state => { state[slice][key] = props; })) }), {})
);

const createPlayerSlice = set => ({
    player: {
        speed: 1,
        step: 0.05,
        play: () => set(produce(state => {
            if (state.player.pos < state.player.duration) state.player.running = true;
        })),
        pause: () => set(produce(state => {
            state.player.running = false;
            state.player.startTime = state.player.time;
        })),
        tick: () => set(produce(state => {
            state.player.time = round(state.player.time + state.player.step, 3);
        })),
        ...getSetters(set, "player", ["feed", "time", "pos", "startTime", "duration", "chat", "actors", "speed", "recordId", "readTime"]),
    },
    updatePlayer: props => set(state => ({ player: {...state.player, ...props } })),
});
const selectUpdatePlayer = state => state.updatePlayer;
export const useUpdatePlayer = () => useStore(selectUpdatePlayer);

export const useStore = create(devtools(set => ({
    update: (props) => set(state => ({...state, ...props })),
    updateItem: item => set(state => ({...state, item })),
    updateMenu: menu => set(state => ({...state, menu })),
    updatePanels: panels => set(state => (
        {...state, panels: { ...(state.panels||{}), ...panels } }
    )),
    toggleMenuPanel0: () => set(state => {
        if (state.panels.menu.open) return {
            ...state, panels: {...state.panels, menu: {...state.panels.menu, open: false } }
        };
        const panels = {...state.panels };
        panels.menu.open = true;
        if (panels.settings.open) {
            panels.settings.open = false;
        }
        return {...state, panels };
    }),
    toggleMenuPanel: () => set(produce(state => {
        if (state.panels.menu.open) {
            state.panels.menu.open = false;
            return;
        };
        state.panels.menu.open = true;
        if (state.panels.settings.open) {
            state.panels.settings.open = false;
        }
    })),
    closeMenuPanel: () => set(state => {
        if (state.panels.menu.open) return {
            ...state, panels: {...state.panels, menu: {...state.panels.menu, open: false } }
        };
        return state;
    }),
    togglePanel: name => set(state => {
        return {
            ...state, panels: {...state.panels, [name]: {...state.panels[name], open: !state.panels[name].open } }
        }
        /* if (state.panels[name].open) return {
            ...state, panels: {...state.panels, [name]: {...state.panels[name], open: false } }
        };
        return {...state, panels: Object.entries(state.panels).reduce((obj, [key, val]) => ({...obj, [key]: {...val, open: !(key!==name && val.drawer) } }), {}) }; */
    }),
    ...createUserSlice(set),
    ...createAppSlice(set),
    ...createPlayerSlice(set),
})));

export const useS = (key, comp) => {
    // console.log("selector", key);
    const callback = useCallback((state) => getDeepKeyValue(state, key), [key]);
    const res = useStore(callback, comp);
    return res;
};

const unsubscribePrefs = useStore.subscribe(
    prefs => {
        // console.log("prefs changed:", prefs);
        const cookie = JSON.parse(localStorage.getItem(cookieKey)) || {};
        if (!objInObj(prefs, cookie.prefs)) {
            // console.log("%crewrite prefs to cookie", "color:#517EF0");
            localStorage.setItem(cookieKey, JSON.stringify({...cookie, prefs }));
        } else {
            // console.log("cookie already contains current prefs");
        }
    },
    state => state.user.prefs
);

const selectUpdate = state => state.update;
export const useUpdate = () => useStore(selectUpdate);

const selectUpdateMenu = state => state.updateMenu;
export const useUpdateMenu = () => useStore(selectUpdateMenu);

const selectUpdateItem = state => state.updateItem;
export const useUpdateItem = () => useStore(selectUpdateItem);

const selectUpdatePanels = state => state.updatePanels;
export const useUpdatePanels = () => useStore(selectUpdatePanels);

const selectToggleMenuPanel = state => state.toggleMenuPanel;
export const useToggleMenuPanel = () => useStore(selectToggleMenuPanel);

const selectCloseMenuPanel = state => state.closeMenuPanel;
export const useCloseMenuPanel = () => useStore(selectCloseMenuPanel);

const selectTogglePanel = state => state.togglePanel;
export const useTogglePanel = () => useStore(selectTogglePanel);

const selectEntryById = id => state => state.entries.find(entry => entry.id === id);
export const useSelectEntry = id => useStore(selectEntryById(id));

const selectPrefs = state => state.user.prefs;
export const usePrefs = () => useStore(selectPrefs);

const selectApp = state => state.app;
export const useApp = () => useStore(selectApp);

const selectUpdateApp = state => state.updateApp;
export const useUpdateApp = () => useStore(selectUpdateApp);

const selectMergeApp = state => state.mergeApp;
export const useMergeApp = () => useStore(selectMergeApp);

const selectToggleDrawer = state => state.toggleDrawer;
export const useToggleDrawer = () => useStore(selectToggleDrawer);

const selectOpenDrawer = state => state.openDrawer;
export const useOpenDrawer = () => useStore(selectOpenDrawer);

const selectCloseDrawers = state => state.closeDrawers;
export const useCloseDrawers = () => useStore(selectCloseDrawers);

const selectMarkAsRead = state => state.markAsRead;
export const useMarkAsRead = () => useStore(selectMarkAsRead);

const selectUpdatePrefs = state => state.updatePrefs;
export const useUpdatePrefs = () => useStore(selectUpdatePrefs);
