import { Box, Heading, useStyleConfig } from "@chakra-ui/react";
import { useEffect } from "react";
import { usePrevious } from "react-use";
import { useS, useStore } from "../../../store";
import Player from "../player";

const itemType = item => item.type.replace(/\/item$/, "");

const Item = () => {

    const item = useS("item");

    const styles = useStyleConfig("Item");

    const menuDrawerOpen = useStore(state => state.panels.menu.drawer && state.panels.menu.open);

    const display = menuDrawerOpen ? "none" : "flex";

    // const prevItem = usePrevious(item);

    /* useEffect(() => {
        console.log("Item:", item);
        console.log("prevItem:", prevItem);
    }, [item, prevItem]); */

    if (!item) return null;

    switch (itemType(item)) {
        case "record": {
            return (
                <Box __css={{...styles, display }} name="item">
                    <Player record={item} onPause={menuDrawerOpen} />
                </Box>
            );
        }
        default: {
            return <Box __css={styles} name="item">unknown</Box>;
        }
    }
};

export default Item;
