import { sidebarSize } from "../sidebar/style";

export const layout = {
    baseStyle: {
        display: "flex",
        flexGrow: 1,
        minHeight: "-webkit-fill-available",
        flexDirection: ["column", null, "row"],
        pb: [sidebarSize.horizontalHeight, null, "unset"],
        pr: [null, null, sidebarSize.verticalWidth],
    }
};
