import { Box, Flex } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import Spinner from "../3/components/spinner";
import { useRequiredData } from "./data";
import { useHyph } from "./hyph";
import { usePrefs } from "./prefs";


export const cookieKey = "zz";

const Boot = ({ children }) => {

    const dataStatus = useRequiredData();

    const prefsStatus = usePrefs();

    const hyphStatus = useHyph();

    // console.log(hyphStatus);

    const isSuccess = dataStatus.isSuccess && prefsStatus && hyphStatus;
    const error = dataStatus.error;

    const [wait, setWait] = useState(true);
    const t = useRef();
    const timeout = 1000;

    useEffect(() => {
        if (!isSuccess) return;
        t.current = setTimeout(() => { setWait(false); }, timeout);
        return () => { clearTimeout(t.current); }
    }, [isSuccess]);

    return !wait
    ? children
    : error
    ? <Box>Error: { error }</Box>
    : <Flex h="100vh">
        <Spinner size="xl" />
    </Flex>
};

export default Boot;
