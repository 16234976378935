import { sidebarSize } from "../sidebar/style";

export const menu = {
    parts: ["panel", "menu", "head", "list"],
    baseStyle: props => {
        return {
            panel: {
                bg: "bs.gray.100",
                flexGrow: 1,
                flexBasis: [null, null, "33%", "40%"],
                position: ["unset", null, "unset"],
                w: ["100vw", null, "unset"],
                height: "-webkit-fill-available",
                // height: "1px",
                overflowY: "scroll",
                // h: [`calc(100vh - ${sidebarSize.horizontalHeight})`, null, "unset"],
                zIndex: 101,
                // textAlign: "center",
            },
            menu: {
                display: "flex",
                flexDirection: "column",
                h: ["1px", null, "100vh"],
                py: "20px",
                px: ["10px", "6%"]
            },
            head: {
                display: "flex",
                // flexDirection: "column",
                flexWrap: "wrap",
                gap: "15px",
                // p: "10px",
                mb: "25px",
                alignItems: "flex-start",
            },
            list: {
                display: "inline-flex",
                flexWrap: "wrap",
                // justifyContent: "space-between",
                alignContent: "flex-start",
                gap: "15px 20px",
                pb: "20px",
                // p: "10px",
            },
        }
    }
};
