
const colors = {
    warm: {
        50: "#FAFAFA",
        100: "#F3F2F1",
        200: "#EAE9E8",
        300: "#D8D6D4",
        400: "#B4B1AD",
        500: "#8A847E",
        600: "#5D5955",
        700: "#3E3B38",
        800: "#252321",
        900: "#1E1D1B",
    },
    bs: {
        light: {
            accent: { green: "#2D828A", red: "#D1194A", yellow: "#E8FD92", },
        },
        white: "#F8F6ED",
        black: "#0E0F17",
        green: "#84B5AF",
        bright: { green: "#45BDB6" },
        gray: {
            100: "#E8ECE8",
            200: "#D1D5D1",
            400: "#A2A6A2",
            700: "#515453",
        }
    },
    chat: {
        line: {
            "light1": "#EFEBF0",
            "light2": "#E6F0E7",
            "light3": "#E8ECF0",
            "light4": "#F6EDD7",
            "light5": "#E4EDED",
            "light6": "#EEEEEE",
            "light7": "#E8E9F3",

            "light8": "#EFEBF0",
            "light9": "#E6F0E7",
            "light10": "#E8ECF0",
            "light11": "#F6EDD7",
            "light12": "#E4EDED",
            "light13": "#EEEEEE",
            "light14": "#E8E9F3",


        }
    }
};

export default colors;
