import { Box, useStyleConfig } from "@chakra-ui/react";
import { isEqual } from "lodash";
import { useEffect } from "react";
import { useS } from "../../../store";
import Item from "../item";
import Menu from "../menu";
import Settings from "../settings";
import Sidebar from "../sidebar";

const Layout = () => {

    const styles = useStyleConfig("Layout");

    const panels = useS("panels");

    /* useEffect(() => {
        console.log("Layout: after render panels changed:", JSON.stringify(panels, null, 2));
    }, [panels]);

    useEffect(() => {
        console.log("Layout: after render styles changed:", styles);
    }, [styles]); */

    if (!panels) return null;


    return (<>
        <Box __css={styles} name="layout">
            <Item />
            { (panels.menu.open || !panels.menu.drawer) && <Menu /> }
            { panels.settings.open && <Settings /> }
        </Box>
        <Sidebar />
    </>)
};

export default Layout;
