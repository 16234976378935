import { useMultiStyleConfig } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { getJSON } from "../../../api/json";
import { getText } from "../../../api/text";
import { useStore, useUpdatePlayer } from "../../../store";
import Spinner from "../spinner";
import Device from "./Device";
import { parseActors, parseText } from "./parse";


const Player = ({ record, onPause }) => {

    // console.log("Player: record.id:", record.id);
    // const qc = useQueryClient();

    const { status: statusA, data: actors } = useQuery(
        [record.id, "json"],
        () => getJSON(`${record.file}/actors.json`)
        .then(data => parseActors(data)),
        { refetchOnWindowFocus: false }
    );

    const { status, data: chat } = useQuery(
        [record.id, "text"],
        () => getText(`${record.file}/chat.txt`)
        .then(data => parseText(data, actors, record.file)),
        { refetchOnWindowFocus: false, enabled: !!(statusA==="success" && actors) }
    );

    const styles = useMultiStyleConfig("Player");

    const
        updatePlayer = useUpdatePlayer(),
        recordId = useStore(state => state.player.recordId);

    useEffect(() => {
        if (!chat) return;
        const images = chat.filter(line=>line.action==="image");
        // https://stackoverflow.com/questions/42615556/how-to-preload-images-in-react-js
        if (images.length > 0) {
            // console.log(`chat images:`, images);
            images.forEach(({ content }) => {
                const newImage = new Image();
                newImage.src = content.src;
                window[newImage.src] = newImage;
            });
        }

        updatePlayer({
            actors,
            chat,
            recordId: record.id,
            time: null,
            pos: 0,
            duration: chat[chat.length-1].time,
            frames: chat.map(line => line.time),
            feed: [],
            running: true,
            readTime: 0,
            startTime: 0, 
        });
    }, [record.id, actors, chat, updatePlayer]);

    if (statusA === "error" || status === "error") {
        return "error";
    }

    if (!recordId) return <Spinner size="xl" />;

    // console.log(read);

    return <Device {...{ styles, onPause }} />
};

export default Player;
