import { sidebarSize } from "../sidebar/style";

export const settings = {
    baseStyle: {
        bg: "#e8e9ecf0",
        position: ["fixed"],
        left: [0, null, "unset"],
        bottom: [sidebarSize.horizontalHeight, null, "unset"],
        right: [null, null, sidebarSize.verticalWidth],
        w: ["100vw", null, "33vw"],
        h: [`33vh`, null, `100vh`],
        zIndex: 102,
    }
};