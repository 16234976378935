import { useEffect, useState } from "react";
import { objType } from "../shed";
import { useS } from "../store";

export const useHyph = () => {

    // const [hyphenator, set] = useState();

    const update = useS("update");
    const hyphenator = useS("hyphenator");

    // const [fn, set] = useState();

    useEffect(() => {
        if (hyphenator && objType(hyphenator) === "function") return;
        async function getHyphenator() {
            window.Hyphenopoly.hyphenators["ru"].then(
                fn => {
                    // console.log(fn("Дома Левиных и Щербацких были старыедворянскиемосковские дома и всегда были между собою в близких и дружеских отношениях.", ".hyph"));
                    update({ hyphenator: str => fn(str, ".hyph") });
                }
            );
        }
        getHyphenator();
        // set(fn);

    }, [hyphenator, update]);

    /* useEffect(() => {
        if (fn) {
            update({ hyphenator: str => fn(str, ".hyph") });
        }
    }, [fn, hyphenator, update]); */

    /* useEffect(() => {
        if (hyphenator) update({ hyphenator: str => hyphenator(str, ".hyph") });
    }, [hyphenator, update]); */

    return hyphenator && objType(hyphenator) === "function";
};