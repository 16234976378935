import { useEffect } from "react";
import { cookieKey } from ".";
import { useS, useStore } from "../store";

export const usePrefs = () => {

    const defaults = useS("config.defaults");
    const prefs = useS("user.prefs");
    const updatePrefs = useStore(state => state.updatePrefs);

    // console.log("prefs:", prefs);

    useEffect(() => {
        // console.log("defaults, prefs:", defaults, prefs);
        if (defaults && !prefs) {
            // console.log( JSON.parse(localStorage.getItem(cookieKey))||{} );
            // console.log("set prefs");
            const savedPrefs = (JSON.parse(localStorage.getItem(cookieKey))||{}).prefs || defaults;
            // console.log(savedPrefs);
            updatePrefs(savedPrefs);
        }
    }, [defaults, prefs, updatePrefs]);

    return !!prefs;

};
