import { useBreakpoint } from "@chakra-ui/react";
import { isEqual, merge } from "lodash";
import { useEffect, useLayoutEffect } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { useS, useUpdatePanels } from "../../store";

const Size = () => {

    const bp = useBreakpoint();

    // console.log("Size: breakpoint:", bp);

    const
        panels = useS("panels", isEqual),
        entries = useS("entries"),
        updatePanels = useUpdatePanels(),
        { pathname } = useLocation();

    const isMenu = !!(entries?.find(e => !!matchPath(e.href, pathname))?.entries);

    useLayoutEffect(() => {
        if (!entries) return;
        if (!bp) return;
        let updated;
        if (!panels) {
            switch (bp) {
                case "base": case "sm": {
                    updated = {
                        item: { drawer: false, },
                        menu: {
                            drawer: true,
                            open: isMenu,
                        },
                        settings: { drawer: true, open: false, }
                    };
                    break;
                }
                default: {
                    updated = {
                        item: { drawer: false, },
                        menu: { drawer: false, open: isMenu, },
                        settings: { drawer: true, open: false }
                    };
                }
            }
        } else {
            // console.log("Size: panels exist, menu:", menu);
            // console.log("Size: panels exist, isMenu:", isMenu);
            switch (bp) {
                case "base": case "sm": {
                    updated = {
                        menu: {
                            drawer: true,
                            // open: panels.menu.open, // isMenu
                        }
                    };
                    break;
                }
                default: {
                    updated = {
                        menu: {
                            drawer: false,
                            // open: true,
                        },
                    };
                }
            }
            updated = merge({}, panels, updated);
        }
        if (!isEqual(panels, updated)) {
            // console.log("Size: update panels");
            // console.log("current:", JSON.stringify(panels, null, 2));
            // console.log("next:", JSON.stringify(updated, null, 2));
            // console.log("is equal?", isEqual(panels, updated));
            updatePanels(updated);
        }

    }, [entries, panels, updatePanels, bp, isMenu]);

    return null;
};

export default Size;
