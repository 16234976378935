import { Box, useStyleConfig, Heading, Button } from "@chakra-ui/react";
import { useCallback } from "react";
import { cookieKey } from "../../../boot";
import { useS, useUpdatePrefs } from "../../../store";


const Settings = () => {

    const styles = useStyleConfig("PanelSettings");

    const prefs = useS("user.prefs");
    const entries = useS("entries");
    const updatePrefs = useUpdatePrefs();

    const clearAll = useCallback(() => {
        localStorage.removeItem(cookieKey);
    }, []);

    const handleClick = useCallback(() => {
        updatePrefs({ read: [] });
    }, [updatePrefs]);

    return (
        <Box __css={styles}>
            <Heading>Clear all</Heading>
            <Button onClick={clearAll}>Clear all</Button>
            <Heading>Read items</Heading>
            { prefs.read && prefs.read.map((id, i) => {
                const entry = entries.find(item=>item.id===id);
                return <Box key={i}>{entry ? entry.title : "broken cookie"}</Box>
            }) }
            <Button onClick={handleClick}>Clear read list</Button>
        </Box>
    )
};

export default Settings;
