import { Link } from "react-router-dom";
import { cl } from "../../../shed";
import { Card } from "./card";
import Cassette from "./cassette";
import Text from "./text";

const components = {
    "record/menu": Card,
    "record/item": Cassette,
    "text/item": Text,
};

export const Thumbnail = ({ entry, link, active, current, parent, size, handleClick }) => {

    const Component = components[entry.type];

    const props = { entry, link, size, handleClick, variant: cl({active, parent, current}) };

    return (
        <Component {...props } />
    );
    /* return (
        link
        ? (<Link to={entry.href}>
            <Component {...props } />
        </Link>)
        : <Component {...props } />
    ) */
};
