import { Box, useStyles } from "@chakra-ui/react";
import Actor from "./Actor";

const Status = ({ action, setNode }) => {

    const { line: style } = useStyles();

    return (
        <Box ref={setNode?node=>{setNode(node)}:null} {...style.box} name="line">
            <Box name="remarque" {...style.remarque}>
                {
                    <Actor style={{
                        as: "span",
                        display: "inline-block",
                        // alignItems: "center",
                        avatar: { size: "xs", mr: "3px" },
                        name: { as: "span", fontWeight: "bold" }
                    }} actor={action.actor} name />
                }
                { ` ${action.content}` }
            </Box>
        </Box>
    )

};

export default Status;
