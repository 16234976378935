import {
    ChakraProvider,
    Box,
    Flex,
    Text,
    Link,
    VStack,
    Code,
    Grid,
} from '@chakra-ui/react';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import { Logo } from './Logo';

import '@fontsource/inter';
import theme from './theme';
import "./App.css";

import smoothscroll from "smoothscroll-polyfill";
import { QueryClient, QueryClientProvider } from 'react-query';
import Boot from './boot';
import { Sized } from './size';


import Router from "./3/route";
import Layout from "./3/components/layout";
import Size from './3/size';
import { Routes, Route, Navigate } from 'react-router-dom';
import Test from './test';

smoothscroll.polyfill();


function AppDefault() {
    return (
        <ChakraProvider theme={theme}>
            <Box textAlign="center" fontSize="xl">
                <Grid minH="100vh" p={3}>
                    <ColorModeSwitcher justifySelf="flex-end" />
                    <VStack spacing={8}>
                        <Logo h="40vmin" pointerEvents="none" />
                        <Text>
                            Edit <Code fontSize="xl">src/App.js</Code> and save
                            to reload.
                        </Text>
                        <Link
                            color="teal.500"
                            href="https://chakra-ui.com"
                            fontSize="2xl"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Learn Chakra
                        </Link>
                    </VStack>
                </Grid>
            </Box>
        </ChakraProvider>
    );
}

// console.log(theme);


function AppDev() {
    const qc = new QueryClient();
    return (
        <ChakraProvider theme={theme}>
            <QueryClientProvider client={qc}>
                <Boot>
                    <Sized>
                        {/* <Routes /> */}
                    </Sized>
                </Boot>
            </QueryClientProvider>
        </ChakraProvider>
    );
}

function App3() {

    const qc = new QueryClient();

    return (
        <ChakraProvider theme={theme}>
            <QueryClientProvider client={qc}>
                <Boot>
                    <Routes>
                        <Route path="/test"
                            element={<Test />}
                        />
                        <Route path="/"
                            element={
                                <Navigate to="/records" replace />
                            }
                        />
                        <Route path="*"
                            element={
                                <>
                                <Router />
                                <Size />
                                <Layout />
                                </>
                            }
                        />
                    </Routes>
                </Boot>
            </QueryClientProvider>
        </ChakraProvider>
    );
}

export default App3;
