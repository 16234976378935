import { dev, entities2unicode, objType, urlize } from "../shed";

const keep = entry => dev() ? entry.skip === "prod" || !entry.skip : !entry.skip;

export const mapEntries = entries => {

    // console.log("map entries");

    const parse = ({ obj, level = 0, acc = "", parent }) => {
        if (Array.isArray(obj)) {
            return obj.map(entry => {
                return parse({ obj: entry, level, acc, parent });
            });
        }
        if (objType(obj) === "object") {
            const href = acc + "/" + (obj.href || urlize(obj.title));
            const entry = { ...obj, href, level };
            if (entry.title) {
                // console.log(entry.title);
                entry.title = entities2unicode(entry.title);
                // console.log(entry.title);
            }
            if (parent) {
                entry.parent = parent;
                if (!entry.type && parent.type) {
                    entry.type = parent.type;
                }
            }
            if (obj.hasOwnProperty("entries")) {
                if (entry.type) {
                    if (!/(^|\/)menu$/.test(entry.type)) entry.type += "/menu";
                } else {
                    entry.type = "menu";
                }
                const { entries, ...parent } = entry;
                entry.entries = parse({
                    obj: entries.filter(keep),
                    level: level + 1,
                    acc: href,
                    parent,
                });
            } else {
                if (/(^|\/)menu$/.test(entry.type))
                    entry.type = entry.type.replace(/(^|\/)menu$/, "");
                entry.type += "/item";
            }
            return entry;
        }
    };

    const tree = parse({ obj: entries });

    const arr = [];

    const tree2arr = obj => {

        if (Array.isArray(obj)) {
            obj.forEach(entry => {
                tree2arr(entry);
            });
            return;
        }
        if (objType(obj) === "object") {
            if (!keep(obj)) return;
            arr.push(obj);
            if (obj.hasOwnProperty("entries")) {
                tree2arr(obj.entries);
            }
        }
    };

    tree2arr(tree);

    // console.log(arr);

    return arr;

};
