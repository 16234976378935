import { Box, useStyles } from "@chakra-ui/react";
import { memo } from "react";
import { objType } from "../../../shed";
import { parseString } from "../player/parse";
import Actor from "./Actor";
import { Image } from "./Image";

const Remarque = memo(({ action, actors, setNode }) => {

    // useEffect(() => { console.log(`${action.action} rendered`); });

    const { line: style } = useStyles();

    // console.log(`Remarque action:`, action);

    const { content } = action;

    const contentType = objType(content) === "object"
    ? content.hasOwnProperty("src") ? "image" : null
    : objType(content) === "string" ? "text" : null;

    return (
        <Box ref={setNode && contentType!=="image"?node=>{setNode(node)}:null} {...style.box} name="line">
            <Box name="remarque" {...style.remarque}>
                {
                    contentType === "text"
                    ? parseString(action.content, actors).map((part, i) => (
                        objType(part) === "object" && part.actor
                        ? <Actor style={{
                            as: "span",
                            display: "inline-block",
                            // alignItems: "center",
                            avatar: { size: "xs", mr: "3px" },
                            name: { as: "span", fontWeight: "bold" }
                        }} key={i} actor={part.actor} name alt={part.label} />
                        : part
                    ))
                    : contentType === "image" && <Image {...{ imageObject: content, style: {...style.image, display: "inline"}, setNode }} />
                }
            </Box>
        </Box>
    )
});

export default Remarque;
