import {
    useStyles, Box,
    Slider, SliderTrack, SliderFilledTrack, SliderThumb,
    useToken,
} from "@chakra-ui/react";
import { memo } from "react";
import { useMemo, useState, useEffect } from "react";
import { round } from "../../../shed";
import { useMarkAsRead, useStore } from "../../../store";

const thumbStyle = {
    width: "32px",
    height: "32px",
    outline: "none",
    boxShadow: "none",
    border: "none",
    borderRadius: 0,
    background: "transparent",
};

const Available = ({ readTime, duration }) => {

    const backgroundColor = useToken("colors", "bs.green");

    return (
        <div style={{ backgroundColor,
            width: `${round(readTime/duration*100, 2)}%`,
            height: "5px"}} name="available" />
    )
};

const Progress = memo(() => {

    const { progress: style } = useStyles();

    const {
        setTime, pause, duration, step,
        // time,
        pos,
        recordId,
        setPos,
        readTime, setReadTime,
    } = useStore(state => state.player);

    const
        read = useStore(state => state.user.prefs.read?.includes(recordId)),
        markAsRead = useMarkAsRead();

    /* useEffect(() => {
        return () => { setMaxTime(0); }
    }, [record]); */
    
    const stroke = useToken("colors", "bs.gray.700");

    useEffect(() => {
        if (read && readTime < duration) { setReadTime(duration); return; }
        // if (!read && maxTime > 0) { setMaxTime(0); }
    }, [read, readTime, duration, setReadTime]);

    useEffect(() => {
        if (read) return;
        if (pos >= duration) markAsRead(recordId);
    }, [pos, duration, read, markAsRead, recordId]);

    useEffect(() => {
        if (read) return;
        if (readTime >= pos) return;
        setReadTime(pos);
    }, [pos, read, readTime, setReadTime]);

    // console.log("Progress: maxTime:", maxTime);

    return (
        <Box __css={style}>
            <Slider
                value={pos} min={0} max={duration} step={step}
                onChangeStart={val =>{
                    // console.log("Progress: onChangeStart: val, readTime:", val, readTime);
                    // if (val <= readTime) pause();
                    setPos(val);
                }}
                onChange={val => {
                    // console.log(val);
                    // console.log(time);
                    // console.log("Progress: onChange: readTime:", readTime);
                    if (!read && val > readTime) {
                        // console.log("not read and val > time");
                        // setTime(time);
                        return;
                    }
                    pause();
                    setPos(val);
                }}
                onChangeEnd={() => { setTime(pos) }}
                >
                <SliderTrack h="5px" bg="bs.gray.200">
                    <SliderFilledTrack bg="bs.gray.700" />
                    <Available {...{ readTime, duration }} />
                </SliderTrack>
                <SliderThumb style={thumbStyle}>
                    <svg viewBox="0 0 32 32" width="32" height="32">
                        <path d="M5,27 L16,16 L5,5" fill="none" stroke={stroke} strokeWidth="5" />
                    </svg>
                </SliderThumb>
            </Slider>
        </Box>
    )
});

export default Progress;
