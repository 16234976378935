import { Box, Avatar } from "@chakra-ui/react";

const Actor = ({ actor, name, alt, style }) => {

    // const { line: { actor: style } } = useStyles();

    const { avatar={}, name: nameStyle={}, ...container } = style || {};

    const [prep, label] = alt
    ? /\{a\}/.test(alt)
    ? alt.split("{a}")
    : [null, alt]
    : [null, actor.name];

    return (
        <Box {...container} whiteSpace="nowrap">
            {prep && <span>{prep}</span>}
            <Avatar {...avatar} name={actor.name} src={actor.upic ? `${actor.upic}.png` : null} />
            { name && <Box {...nameStyle}>{ label }</Box>}
        </Box>
    );
};

export default Actor;
