
import { mode } from "@chakra-ui/theme-tools";

export const card = {
    baseStyle: props => {
        return {
            w: "152px",
            h: "96px",
            borderRadius: "8px",
            // px: "14px",
            // py: "6px",
            display: "flex",
            p: "5px 11px 7px 11px",
            bg: "bs.white",
            alignItems: "flex-end",
            borderWidth: "3px",
            borderColor: "bs.gray.200",
            justifyContent: "flex-end",
        }
    },
    variants: {
        parent: {
            bg: "bs.light.accent.green",
            color: "bs.white",
            w: "unset",
            h: "unset",
            border: "unset",
            pl: "8px",
            pr: "14px",
            alignItems: "baseline",
            justifyContent: "flex-start",
        },
        current: {
            w: "unset",
            h: "unset",
            border: "unset",
            bg: "bs.black",
            color: "bs.white",
            px: "14px",
            alignItems: "baseline",
            justifyContent: "flex-start",
        },
        active: {
            bg: "bs.light.accent.red",
            color: "bs.white",
            border: "unset",
        }
    },
    defaultProps: {}
}

export const cassette = {
    baseStyle: props => {
        // console.log("theme props:", props);
        const bs = props.theme.colors.bs; return ({

        width: 152,
        height: 96,

        // relative to viewBox 0 0 228 144
        cl: { x: 69, y: 77 },
        cr: { x: 159, y: 77 },
        r: 12,
        wheelStroke: 4,
        maxBandRadius: 64,
        paperMargins: {
            top: 5,
            left: 5,
            right: 5,
            cut: 10,
        },
        caseRadius: 12,
        caseOutline: 1,
        paperRadius: 8,

        textStyle: {
            fontSize: "15px", height: "47px",
            width: "100%", padding: "0 7px 0 7px", textAlign: "center",
        }
    })},
    sizes: {
        
    },
    variants: {
        normal: props => { const bs = props.theme.colors.bs; return {
            // caseColor: mode("red", "blue")(props),
            caseColors: [bs.gray[200]], // no outline
            paperColor: bs.white,
            wheelColors: ["none", bs.gray[200]],
            bandColor: bs.gray[200],
            textColor: bs.black,
            bgColor: "none",
        } },
        active: props => { const bs = props.theme.colors.bs; return {
            // caseColor: mode("red", "blue")(props),
            caseColors: [bs.white, bs.light.accent.red],
            paperColor: bs.light.accent.red,
            wheelColors: [bs.light.accent.red, bs.white],
            bandColor: bs.white,
            textColor: bs.white,
            bgColor: bs.light.accent.red,
        } },
    },
    defaultProps: {
        variant: "normal",
    }
};
