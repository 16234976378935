import { Box, Heading, useMultiStyleConfig, useBreakpoint } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useCloseMenuPanel, useS, useToggleMenuPanel } from "../../../store";
import { Thumbnail } from "../thumbnail";

const Menu = () => {

    const bp = useBreakpoint();

    const styles = useMultiStyleConfig("PanelMenu");

    const
        menu = useS("menu"),
        item = useS("item"),
        panels = useS("panels"),
        closeMenuPanel = useCloseMenuPanel();

    // const { pathname } = useLocation();
    

    if (!menu) return null;

    return (
        <Box __css={styles.panel} name="menu panel">
            <Box __css={styles.menu} name="menu frame">

                <Box __css={styles.head}>
                    { menu.parent && <Thumbnail
                        entry={menu.parent}
                        link
                        parent
                        size={bp}
                    /> }
                    <Thumbnail current entry={menu} size={bp} />
                </Box>

                <Box __css={styles.list}>
                    { menu.entries.map((entry, i) => (
                        <Thumbnail key={i}
                            entry={entry}
                            size={bp}
                            handleClick={
                                /\/item$/.test(entry.type)
                                ? closeMenuPanel
                                : null
                            }
                            active = { item?.id === entry.id }
                            link = { item?.id !== entry.id }
                        / >
                    )) }
                    { /* (new Array(100).fill(1)).map((e, i)=>(
                        <Box key={i}>element {i+1}</Box>
                    )) */ }
                </Box>

            </Box>
        </Box>
    )
};

export default Menu;
