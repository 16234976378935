import { extendTheme } from "@chakra-ui/react";
import colors from "./colors";
import styles from "./styles";
import fonts from "./fonts";
import components from "../3/components";

const overrides = {
    colors,
    styles,
    components,
    fonts,
};

export default extendTheme(overrides);
