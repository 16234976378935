import { merge } from "lodash";
import { useEffect, useState } from "react";
import { useQueries } from "react-query";
import { getTable } from "../api";
import { getJSON } from "../api/json";
import { convertStringsToValues, objInObj, setDeepValue } from "../shed";
import { useStore } from "../store";
import { mapItems } from "./collections";
// import { mapItems } from "./collections";
import { mapEntries } from "./entries";

const required = [
    { json: "/json/config.json", sliceKey: "config" },
    { json: "/json/entries.json", sliceKey: "entries", mapper: mapEntries },
    // { json: "/json/collections.json", sliceKey: "items", mapper: mapItems },
];

const mapper = (data, source) => source.hasOwnProperty("mapper") ? source.mapper(data) : data;

export const useRequiredData = () => {

    const [data, set] = useState();

    const queries = useQueries(required.map(source => ({
        queryKey: ["required", source.sliceKey],
        queryFn: source.json
        ? () => getJSON(source.json).then(data => mapper(data, source))
        : source.sql
        ? () => getTable(source.sql)
            .then(data =>
                convertStringsToValues(data)
                .map(obj => Object.entries(obj)
                    .reduce((acc, [key, value]) => (
                        value===null ? acc : {...acc, [key]: value }
                    ), {})
                )
            )
            .then(data => mapper(data, source))
        : async () => { return Promise.reject("no query function") },
        refetchOnWindowFocus: false,
        enabled: !data,
    })));
    
    const fetched = queries.every(query => query.isSuccess);

    const { error } = queries.find(query => query.error) || {};

    const update = useStore(state => state.update);
    const updated = useStore(state => fetched && objInObj(data, state));

    useEffect(() => {
        // console.log("loaded?", loaded);
        if (fetched && !updated) {
            const state = required.reduce((state, slice, index) => {
                return merge(state, setDeepValue(slice.sliceKey, queries[index].data));
            }, {});
            set(state);
        }
    }, [fetched, updated, queries]);

    useEffect(() => {
        if (data) {
            update(data);
        }
    }, [data, update]);

    if (error) return { error, isError: true };

    return updated
    ? { status: "success", isSuccess: true }
    : { status: "loading", isLoading: true }
};
