import { Box, Center, useStyleConfig } from "@chakra-ui/react";
import { Link } from "react-router-dom";

const Wrap = ({ link, children, ...props }) => {
    return (
        link
        ? <Link to={link} {...props}>
            { children }
        </Link>
        : <Box {...props}>
            { children }
        </Box>
    )
};

export const Cassette = ({ entry: record, link, percentage=0.75, handleClick, size="lg", variant }) => {

    const params = useStyleConfig("Cassette", { size, variant });

    // console.log(params);

    const {
        width, height, cl, cr, r, wheelStroke, maxBandRadius,
        paperMargins, caseRadius, caseOutline, paperRadius,
        caseColors, paperColor, bandColor, wheelColors, bgColor, textColor,
        textStyle,
    } = params;

    const id = record.id,
    w = 228,
    h = 144,
    minBandRadius = r + 2 * wheelStroke,
    br = minBandRadius + (maxBandRadius - minBandRadius) * percentage;

    paperMargins.bottom = h - (cl.y + r + wheelStroke + paperMargins.cut);

    const wheelStyle = {
        fill: wheelColors[0],
        stroke: wheelColors[1],
        strokeWidth: `${wheelStroke}px`,
        // opacity: 0.5
    };

    const caseStyle = {
        fill: caseColors[0],
    }

    const caseOutlineStyle = {
        fill: "none",
        stroke: caseColors[1],
        strokeWidth: caseOutline,
    }

    const paperStyle = {
        fill: paperColor,
    }
    const bandStyle = {
        fill: bandColor,
    }

    const wrapProps = {
        style: {
            width: `${width}px`,
            height: `${height}px`,
            position: "relative",
        },
        onClick: handleClick,
        link: link && record.href,
    };

    return (
        <Wrap {...wrapProps}>
            <svg viewBox={`0 0 ${w} ${h}`} style={{ fillRule: "evenodd", clipRule: "evenodd", position: "absolute", left: 0, top: 0, zIndex: 1 }}>
                <rect x={caseOutline} y={caseOutline} width={228-caseOutline*2} height={144-caseOutline*2} fill={bgColor} rx={caseRadius-caseOutline} />

                <clipPath id={`clipBand-${id}`}>
                    <path d={`M5,5 L${2*maxBandRadius+15},5 L${2*maxBandRadius+15},${h-5} L5,${h-5} L5,5Z
                    M${cl.x},${cl.y - r - 1}
                    A${r+1} ${r+1} 0 0 0 ${cl.x},${cl.y + r + 1}
                    A${r+1} ${r+1} 0 0 0 ${cl.x},${cl.y - r - 1}Z`} />
                </clipPath>
                <g clipPath={`url(#clipBand-${id})`}>
                    <circle cx={cl.x} cy={cl.y} r={br} style={bandStyle} />
                </g>
                <circle id={`L-${id}`} cx={cl.x} cy={cl.y} r={r+wheelStroke/2} style={wheelStyle} />
                <circle id={`R-${id}`} cx={cr.x} cy={cr.y} r={r+wheelStroke/2} style={wheelStyle} />
                <clipPath id={`clipCase-${id}`}>
                    <path d={`M228,0 L0,0 L0,144 L228,144 L228,0Z
                    M${cr.x},${cr.y-r}
                    L${cl.x},${cl.y-r}
                    A${r} ${r} 0 0 0 ${cl.x},${cl.y+r}
                    L${cr.x},${cl.y+r}
                    A${r} ${r} 0 0 0 ${cr.x},${cr.y-r} Z`} />
                </clipPath>
                <g clipPath={`url(#clipCase-${id})`}>
                    <rect id={`case-${id}`} x="0" y="0" width="228" height="144" style={caseStyle} rx={caseRadius} />
                    <rect x={caseOutline} y={caseOutline} width={228-caseOutline*2} height={144-caseOutline*2} style={caseOutlineStyle} rx={caseRadius-caseOutline} />
                </g>
                <clipPath id={`clipPaper-${id}`}>
                    <path d={`M${w-paperMargins.right},${paperMargins.top}
                    L${paperMargins.left},${paperMargins.top}
                    L${paperMargins.left},${h-paperMargins.bottom}
                    L${w-paperMargins.right},${h-paperMargins.bottom}
                    L${w-paperMargins.right},${paperMargins.top} Z
                    M${cr.x},${cr.y-r-wheelStroke}
                    L${cl.x},${cr.y-r-wheelStroke}
                    A${r+wheelStroke/2} ${r+wheelStroke/2} 0 0 0 ${cl.x},${cl.y+r+wheelStroke}
                    L${cr.x},${cr.y+r+wheelStroke}
                    A${r+wheelStroke/2} ${r+wheelStroke/2} 0 0 0 ${cr.x},${cr.y-r-wheelStroke} Z`}/>
                </clipPath>
                <g clipPath={`url(#clipPaper-${id})`}>
                    <rect id={`paper-${id}`}
                    x={paperMargins.left} y={paperMargins.top}
                    width={w-paperMargins.left-paperMargins.right} height={h-paperMargins.bottom-paperMargins.top+paperRadius}
                    rx={paperRadius} style={paperStyle} />
                </g>
            </svg>
            <Center style={{...textStyle, position: "absolute", zIndex: 2, color: textColor }}>{
                record.short || record.title
            }</Center>
        </Wrap>
    )
};

export default Cassette;