import { mode } from "@chakra-ui/theme-tools";

const styles = {
    global: props => ({
        body: {
            bg: mode("bs.white", "bs.black")(props),
        },
        a: {
            color: "blue",
            textDecoration: "underline",
        }
    })
};

export default styles;
