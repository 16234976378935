import { Box, useStyles, useColorModeValue } from "@chakra-ui/react";
import { memo, useEffect, useMemo, useState } from "react";
import { objType } from "../../../shed";
import { useS } from "../../../store";
import { parseEmoji, parseUrls } from "../player/parse";
import Actor from "./Actor";
import Dot from "./Dot";

const dots = (<>
    <Dot delay={0} />
    <Dot delay={0.11} />
    <Dot delay={0.22} />
</>);

const Line = memo(({ action, running, startTime }) => {

    // console.log(action);

    // const actor = actors.find(actor => actor.name === action.actor || actor.syn.includes(action.actor));

    // console.log(action.actor);

    const hyphenator = useS("hyphenator");

    const { actor: { position }, content } = action;

    // const style = styles(position);

    const { line: style } = useStyles();

    const bg = useColorModeValue(
        `chat.line.light${action.actor.color}`,
        `chat.line.dark${action.actor.color}`
    );

    const message = useMemo(() => {
        const
        hasEmoji = /\p{Extended_Pictographic}/u.test(content),
        hasLinks = /http[s]*:\/\/|mailto:/i.test(content);
        let output = [content];
        if (hasEmoji) output = output.map(s => parseEmoji(s, { style: style.emoji }));
        if (hasLinks) output = output.flat(1).map(s => parseUrls(s));
        return output.flat(1).map(s => objType(s)==="string" ?  hyphenator(s) : s);
    }, [content, hyphenator, style]);

    const [typing, setTyping] = useState();

    const timeout = useMemo(() => {
        // console.log(`Line: calc timeout: startTime: ${startTime}, action.time: ${action.time}`);
        return action.typing && (
            startTime > action.time
            ? action.typing - (startTime - action.time)
            : action.typing
        ) * 1000
    }, [action, startTime]);

    useEffect(() => {
        // console.log(`-------------\nLine render`, parsed);
        let tid;
        // console.log(`Line: action.typing: ${action.typing}, running: ${running}`);
        if (action.typing && running && timeout > 0) {
            // console.log(`Line: set typing to true, after ${timeout} to false`);
            // console.log(`startTime:`, startTime);
            setTyping(true);
            // setAnchor("A");
            // console.log(`message after`, timeout);
            tid = setTimeout(() => {
                // console.log(`replace ... with content`);
                setTyping(false);
                tid = null;
                // setAnchor("B");
            }, timeout);
        } else {
            setTyping(false);
            // setAnchor("A");
        }
        return () => {
            // console.log(`unmount`);
            if (tid) {
                // console.log(`clear tid:`, tid);
                clearTimeout(tid);
                tid = null;
            }
        }
    }, [action.typing, running, timeout]);

    if (typing === undefined) return null;

    return (<>
        <Box
            name="line"
            { ...style.box }
            flexDirection = {{ left: "row", right: "row-reverse" }[position]}
        >
            <Actor actor={action.actor}
                style={{
                    avatar: { size: "sm", mx: "6px", mt: "3px" },
                    display: "inline-flex"
                }}
            />

            <Box
                className="hyph"
                lang="ru"
                name="message"
                { ...{...style.message, bg } }
            >
                {
                    typing
                    ? dots
                    : message
                }
            </Box>
            
        </Box>
    </>)
});

export default Line;
