
export const sidebarSize = {
    verticalWidth: "3.75rem",
    horizontalHeight: "3.75rem",
}

export const sidebar = {
    baseStyle: {
        position: "fixed",
        left: [0, null, "unset"],
        bottom: [0, null, "unset"],
        top: [null, null, 0],
        right: [null, null, 0],
        width: ["100vw", null, sidebarSize.verticalWidth],
        height: [sidebarSize.horizontalHeight, null, "100%"], // "-webkit-fill-available"
        display: "flex",
        flexDirection: ["row", null, "column"],
        bg: "bs.light.accent.red",
    }
};
