import { isEqual } from "lodash";
import { useEffect } from "react";
import { useRef } from "react";

export function round(num, places=0) {
    // https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary
    const k = Math.pow(10, places);
    return num === 0 ? 0 : Math.round( ( num + Number.EPSILON ) * k ) / k;
}

export const cap = (string) => string[0].toUpperCase() + string.substring(1);

export const convertStringsToValues = (obj) => {
    if (Array.isArray(obj)) {
        return obj.map((o) => convertStringsToValues(o));
    }
    if (objType(obj) === "string") return str2val(obj);
    if (objType(obj) !== "object") return obj;
    return Object.entries(obj).reduce(
        (res, [key, val]) => ({ ...res, [key]: convertStringsToValues(val) }),
        {}
    );
};

export function getDeepKeyValue(obj, keyString) {
    if (!/\./.test(keyString)) return obj[keyString];
    const keys = keyString.split(".");
    let res = obj;
    for (let i = 0; i < keys.length; i++) {
        res = res[keys[i]];
        if (res === undefined) break;
    }
    return res;
}

export const setDeepValue = (key, value) => {
    if (!/[^\.]+\.[^\.]+/.test(key)) return { [key]: value }

    const nested = key.split(".");

    const first = nested.shift();

    return { [first]: setDeepValue(nested.join("."), value) }
};

export const objInObj = (obj, parent) => {
    if (objType(obj) !== "object" || objType(parent) !== "object") return false;
    return Object.keys(obj).every(
        (key) => parent.hasOwnProperty(key) && isEqual(parent[key], obj[key])
    );
};

export const dev = () => process.env.NODE_ENV === "development";

export function objType(obj) {
    return /^\[object (\w+)]$/
        .exec(Object.prototype.toString.call(obj))[1]
        .toLowerCase();
}

const str2val = (str) => {
    if (objType(str) !== "string") return str;
    try {
        const obj = JSON.parse(str);
        return obj;
    } catch {
        if (/^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d\.\d{3}Z$/.test(str)) {
            // console.log("date found", new Date(str));
            return new Date(str);
        }
        return str;
    }
};

export function removePunctuation(str) {

    const punctuation = `«»„“.,:;!=+—–\\"\\'\\?\\s\\(\\)\\-\\\\\/`;
    const rx = new RegExp(
        `[${punctuation}]+`, "g"
    );
    return str.replace(rx, " ").replace(/\s\s/g, " ").trim();
}

export const urlize = str => {
    const translit = {
        "а": "a", "б": "6", "в": "8", "г": "r",
        "д": "d", "е": "e", "ё": "e", "ж": "z", "з": "3",
        "и": "i", "й": "j", "к": "k", "л": "l", "м": "M",
        "н": "H", "о": "o", "п": "n", "р": "p", "с": "c",
        "т": "t", "у": "y", "ф": "f", "х": "x", "ц": "z",
        "ч": "4", "ш": "w", "щ": "w", "ъ": "", "ы": "b1",
        "ь": "b", "э": "e", "ю": "10", "я": "R"
    };
    return removePunctuation(str).replace(/\s/g, "-").toLowerCase()
    .split("").map(c => translit[c] || c).join("");
};

export function cl() {
    const classList = Array.prototype.slice
        .call(arguments)
        .filter((c) => c)
        .map((c) => {
            if (objType(c) === "object") {
                return Object.keys(c)
                    .map((key) => (c[key] === true ? key : false))
                    .filter((c) => c);
            }
            return c;
        })
        .flat(Infinity);

    // console.log(classList);

    return classList.length > 0 ? classList.filter((c) => c).join(" ") : null;
}

export function useTraceUpdate(props, title) {
    // https://stackoverflow.com/questions/41004631/trace-why-a-react-component-is-re-rendering
    const prev = useRef(props);
    useEffect(() => {
        const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
            if (prev.current[k] !== v) {
                ps[k] = [prev.current[k], v];
            }
            return ps;
        }, {});
        if (Object.keys(changedProps).length > 0) {
            const eq = isEqual(prev.current, props);
            console.log(
                `%cTrace: ${title} props changed ${eq ? "only by reference" : ""}`,
                (eq ? "color:orange": "color:green"),
                changedProps
            );
        }
        prev.current = props;
    });
}

export function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function getRandomValue(array) {
    const arr = array.map((e) =>
        objType(e) === "object"
            ? e.hasOwnProperty("chance")
                ? e
                : { ...e, chance: 1 }
            : { value: e, chance: 1 }
    );

    // console.log(arr);

    const sum = arr.reduce((acc, e) => acc + e.chance, 0);
    //console.log(sum);
    const random = getRandomInt(0, sum - 1);
    //console.log(random);
    let acc = 0;

    for (let i = 0; i < arr.length; i++) {
        const min = acc;
        const max = arr[i].chance + acc - 1;
        //console.log(`for ${arr[i].str}: ${min}-${max}`);
        acc += arr[i].chance;
        if (random >= min && random <= max) {
            return arr[i].hasOwnProperty("value") ? arr[i].value : arr[i];
        }
    }
    return null;
}

export function shuffleArray(array) {
    const newArray = [...array];
    // https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
    for (let i = newArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;
}

export function entities2unicode(str) {
    if (objType(str) !== "string") {
        // console.log("entities2unicode: type mismatch:", str);
        // throw new Error(str);
        return str;
    }
    let res = str;
    [
        { e: "#9312", u: "\u2460" },
        { e: "#9313", u: "\u2461" },
        { e: "#9314", u: "\u2462" },
        { e: "#9315", u: "\u2463" },
        { e: "#9316", u: "\u2464" },
        { e: "#9317", u: "\u2465" },
        { e: "#9318", u: "\u2466" },
        { e: "#9319", u: "\u2467" },
        { e: "#9320", u: "\u2468" },

        { e: "times", u: "\u00D7" },
        { e: "thinsp", u: "\u2009" },
        { e: "copy", u: "\u00A9" },
        { e: "nbsp", u: "\u00A0" },
        { e: "mdash", u: "\u2014" },
        { e: "ndash", u: "\u2013" },
        { e: "shy", u: "\u00AD" },
        { e: "bdquo", u: "\u201E" },
        { e: "ldquo", u: "\u201C" },
        { e: "laquo", u: "\u00AB" },
        { e: "raquo", u: "\u00BB" },
    ].forEach((entity) => {
        res = res.replace(new RegExp(`&${entity.e};`, "g"), entity.u);
    });
    return res;
}

export function lz(num) {
    return num < 10 ? "0" + num : num.toString();
}

export const time2sec = time => round(time.hh * 3600 + time.mm * 60 + time.ss + (time.ms / 1000), 2);

const div = (a, b) => ({ int: Math.floor(a / b), rem: round(a % b, 3) });

export const sec2time = sec => {
    const { int: hh, rem: rm } = div(sec, 3600),
    { int: mm, rem: rs } = div(rm, 60),
    { int: ss, rem } = div(rs, 1),
    ms = rem * 1000;
    return { hh, mm, ss, ms }
};
