import { Box } from "@chakra-ui/react";
import { Link } from "react-router-dom";

const Text = ({ entry, link, size, variant }) => {

    // console.log(`props:`, { entry, link, size, variant });

    return (
        link
        ? <Link to={entry.href}>text</Link>
        : <Box>text</Box>
    )
};

export default Text;
