import { Box, useStyles, useToken } from "@chakra-ui/react";
import { memo, useMemo } from "react";
import { lz, round } from "../../../shed";
import { useStore } from "../../../store";
import { Cassette } from "./cassette";

const triangle = (k) => {
    const base = {
        r: 0.5,
        p1: { x: 1, y: 0.5 },
        p2: { x: 0.25, y: 1 - 0.0666666 },
        p3: { x: 0.25, y: 0.0666666 },
        c: { x: 0.5, y: 0.5 },
    };
    return Object.entries(base).reduce((res, [key, val]) => (
        {...res, [key]: val.x ? { x: val.x * k, y: val.y * k } : val * k }
    ), {});
}

const t32 = triangle(27.5);

const icons = {
    play0: (<>
        <clipPath id="round"><circle r={t32.r-0.5} cx={t32.c.x} cy={t32.c.y} /></clipPath>
        <polygon clipPath="url(#round)" points={["p1", "p2", "p3"].map(p => `${t32[p].x},${t32[p].y}`).join(" ")} />
    </>),
    play: (<>
        <path d="M16,0C24.831,0 32,7.169 32,16C32,24.831 24.831,32 16,32C7.169,32 0,24.831 0,16C0,7.169 7.169,0 16,0ZM27.42,16L10.296,25.886L10.296,6.114L27.42,16Z" />
    </>),
    pause: (<>
        <rect x="1" y="5" width="7" height="10" />
        <rect x="11" y="5" width="7" height="10" />
    </>),
    reset: (<>
        <rect x="0" y="5" width="4" height="10" />
        <polygon points="4,10 20,5 20,15" />
    </>)
};

const Play = memo(({ running, pause, play, }) => {
    const colors = useToken("colors", ["bs.gray.400", "#4CC3B4"]);
    return (
        <Box w="28px" h="28px" cursor="pointer" onClick={running ? pause : play}>
            <svg viewBox="0 0 32 32" fillRule="evenodd">
                <g fill={colors[running?1:0]}>
                    { icons.play }
                </g>
            </svg>
        </Box>
    )
})

const Key = ({ icon, active, handleClick }) => {
    return (
        <Box w="32px" h="32px" cursor={active?"default":"pointer"} onClick={handleClick}>
            <svg viewBox="0 0 32 32">
                <g fill={active?"#00ff00":"white"}>
                    { icons[icon] }
                </g>
            </svg>
        </Box>
    );
};

const Keys = memo(({ reset, start, pause, running, middle }) => {
    // useEffect(() => { console.log("keys render") });
    return (<>
        <Key icon="play" handleClick={running?pause:start} active={running} />
        <Key icon="reset" handleClick={reset} />
        <Key icon="pause" handleClick={pause} active={!running && middle} />
    </>)
});

const Display = memo(({ time, duration, running }) => {

    // console.log(time);

    return (
        <Box
            w="6rem"
            bg={ running ? "bs.green" : "bs.gray.400" }
            borderRadius="5px"
            display="flex"
            justifyContent="space-around"
            fontSize="13px"
            py="4px"
        ><Box>{ time }</Box><Box>{ duration }</Box></Box>
    )
});

const Controls = memo(() => {

    const { controls: style } = useStyles();

    const {
        play, pause, running, pos, duration
    } = useStore(state => state.player);

    const t = useMemo(() => {
            // const sec = Math.floor(time);
            // console.log(pos);
            return `${Math.floor(pos / 60)}:${lz(Math.floor(pos % 60))}`;
        }, [pos]);

    const d = useMemo(() => {
        // console.log(`duration:`, duration);
            const res = `${Math.floor(duration / 60)}:${lz(Math.floor(duration % 60))}`;
            return res;
        }, [duration]);

    // useEffect(() => { console.log("controls render") });

    // useEffect(() => { console.log("style changed"); }, [style]);

    // console.log("Controls: play, pause:", play, pause);

    // console.log("Controls: pos, duration:", pos, duration);

    return (
        <Box __css={style}>
            <Box w={58 * 1.5} h={20 * 1.5}>
                <Cassette {...{ length: 30 * 60, duration, pos: pos / duration, running }} />
            </Box>
            <Play {...{ running, play, pause }} />
            {/* <Keys {...{ reset, start, pause, running, middle }} /> */}
            <Box flexGrow="1" />
            <Display {...{ time: t, duration: d, running }} />
        </Box>
    );
});

export default Controls;
