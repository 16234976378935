
export const getText = async (file, params={}) => {
    const appliedParams = { cache: "no-store", ...params }
    const res = await fetch(file, appliedParams)
    .then(response => {
        // console.log(response);
        return response.text();
    })
    .catch(error => {
        /* if (error.name === "AbortError") {
            console.log("fetch aborted");
            return Promise.resolve();
        } */
        return Promise.reject(error);
    });
    return res;
};
