import { useLocation, matchPath } from "react-router-dom";
import { useEffect, useMemo } from "react";
import { useS, useUpdateItem, useUpdateMenu } from "../../store";

const
    isItem = entry => /\/item$/.test(entry.type),
    isMenu = entry => /\/menu$/.test(entry.type);

const Router = () => {

    const { pathname } = useLocation();

    const
        entries = useS("entries"),
        item = useS("item"),
        menu = useS("menu"),
        updateItem = useUpdateItem(),
        updateMenu = useUpdateMenu();

    const entry = useMemo(() => entries && entries.find(e => {
        const res = matchPath(e.href, pathname);
        // if (res) console.log(`%cRouter: found entry for ${pathname}`, "color:silver");
        return res;
    }), [entries, pathname]);

    // const [status, set] = useState();

    useEffect(() => {
        if (!entry) return;
        if (!isMenu(entry)) return;
        if (!menu) { updateMenu(entry); return; }
        if (menu.id !== entry.id) updateMenu(entry);
    }, [entry, menu, updateMenu]);
    
    useEffect(() => {
        if (!entry) return;
        if (!isItem(entry)) return;
        if (!item) { updateItem(entry); return; }
        if (item.id !== entry.id) updateItem(entry);
    }, [entry, item, updateItem]);

    useEffect(() => {
        if (!menu && entry && isItem(entry)) {
            const parentMenu = entries.find(e => e.id === entry.parent.id);
            updateMenu(parentMenu);
        }
    }, [entry, item, menu, updateMenu, entries]);

    // console.log(entry);

    return null;

};

export default Router;
