
export const getJSON = async (file, params={ cache: "no-store" }) => {
    const res = await fetch(file, params)
    .then(response => {
        // console.log(response);
        return response.json();
    })
    .catch(error => {
        // console.log("Error:", error);
        // console.log("name:", error.name);
        return Promise.reject(error);
        // return Promise.reject(error.message ? error.message : error);
    });
    return res;
};
