import { dev } from "../shed";

const api_host = "https://jauns.runajiet.lv/api";

const api_post = api_host + "/update/",
    api_select = api_host + "/select/",
    api_session = api_host + "/session/";

export const getData = (api) => async (data={}, params={}) => {

    const request = {
        cache: "no-store",
        ...params,
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-type": "application/json"
        },
        body: JSON.stringify(data)
    };

    // console.log(request);

    const res = await fetch(api, request)
    .then(response => {
        // console.log(response.clone().text());
        return response.json();
    })
    .then((response) => {
        if (response.error) {
            if (response.log) {
                console.log(response.log);
            }
            throw response.error;
        }
        console.log(response.sql);
        // console.log(response);
        return response.data;
    })
    .catch(error => {
        // console.log("Error:", error);
        // return error;
        return Promise.reject(error);
    });
    return res;
};

export const getTable = getData(api_select);
export const getSession = getData(api_session);

export const post2server = async (request) => {
    // console.log("api request:", request);
    const headers = {
        "Accept": "application/json",
        "Content-type": "application/json"
    };
    const jsonRequest = {
        method: "POST",
        headers,
        body: JSON.stringify(request)
    };
    const res = dev()

    ? await fetch(api_post, jsonRequest)
    .then(response => {
        // console.log(response);
        return response.json();
    })
    .then(response => {
        if (response.error) {
            if (response.log) console.log(response.log, request);
            throw response.error;
        }
        // console.log(response);
        return request.data;
    })
    .catch(error => {
        console.log("Error:", error);
        // console.log(request);
        // return error;
        return Promise.reject(error);
    })

    : await fetch(api_session, {
        method: "POST",
        headers
    })
    .then(response => {
        // console.log("session raw response:", response);
        return response.json();
    })
    .then(session => {
        const token = session.data;
        // console.log("session data:", token);
        // console.log("request:", request);
        return fetch(api_post, {
            ...jsonRequest,
            headers: {...headers, "X-Mas-Lvx": token }
        })
        .then(response => {
            // console.log(response);
            return response.json();
        })
        .then(response => {
            if (response.error) {
                if (response.log) console.log(response.log, request);
                throw response.error;
            }
            return request.data;
        });

    })
    .catch(error => {
        console.log("post error:", error);
        Promise.reject(error);
    });

    return res;
}
